import { useEventListener, usePrevious } from '@kontent-ai/hooks';
import { PropsWithChildren, RefObject, useEffect, useRef } from 'react';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
  ContentItemEditingGeneralAction,
} from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { isDisabled } from '../../../../../_shared/utils/contentItemUtils.ts';
import { TemporaryContentItemState } from '../../../../contentInventory/content/models/temporaryContentItemState.ts';
import { newlyCreatedItemEditorMounted } from '../actions/contentItemEditingActions.ts';
import { ContentItemEditor as ContentItemEditorComponent } from '../components/ContentItemEditor.tsx';
import { useIsJustCreatedItemClone } from '../context/IsJustCreatedItemCloneContext.tsx';

type Props = PropsWithChildren<
  Readonly<{
    contentItemCanvasRef?: RefObject<HTMLDivElement>;
    isPreviewOpen?: boolean;
  }>
>;

export const ContentItemEditor = (props: Props) => {
  const dispatch = useDispatch();

  const { isJustCreatedItemClone } = useIsJustCreatedItemClone();

  const isNewlyCreatedItem = useSelector((s) => {
    const { temporaryItem } = s.contentApp.editorUi;
    return (
      (temporaryItem && temporaryItem.itemState !== TemporaryContentItemState.Edited) ||
      isJustCreatedItemClone
    );
  });

  useEffect(() => {
    if (isNewlyCreatedItem) {
      dispatch(newlyCreatedItemEditorMounted());
    }
  }, [isNewlyCreatedItem]);

  const disabled = useSelector((s) => isDisabled(false, s.contentApp.editedContentItemVariant));
  const contentItemName = useSelector((s) => s.contentApp.editedContentItem?.name ?? null);
  const progressMessage = useSelector((s) => s.contentApp.progressMessage);

  const allEditingDataLoaded = useSelector((s) => {
    const {
      editedContentItem,
      editedContentItemVariant,
      editedContentItemVariantElements,
      loadedContentItemTypes,
    } = s.contentApp;

    return !!(
      editedContentItem &&
      loadedContentItemTypes.get(editedContentItem.editedContentItemTypeId) &&
      editedContentItemVariant &&
      editedContentItemVariantElements
    );
  });

  useTrackCopyToClipboard();
  useTrackFirstItemChange();

  if (progressMessage) {
    return (
      <div className="utility-message utility-message--centered utility-message--in-progress">
        <h1 className="utility-message__title">{progressMessage}</h1>
      </div>
    );
  }

  return allEditingDataLoaded ? (
    <ContentItemEditorComponent
      contentItemCanvasRef={props.contentItemCanvasRef}
      contentItemName={contentItemName}
      disabled={disabled}
      isPreviewOpen={!!props.isPreviewOpen}
    />
  ) : (
    <div css="grid-area: editor;">
      <Loader />
    </div>
  );
};

const useTrackCopyToClipboard = () => {
  const dispatch = useDispatch();

  // For simplicity, we track all copy events regardless of placement (both editable and non-editable content)
  // We can consider copying various things from content item, e.g. task text, comment, contributors
  // as an action that led the user to navigate to this specific item
  useEventListener(
    'copy',
    () => {
      dispatch(
        trackUserEvent(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingGeneralAction.CopyToClipboard,
          origin: ContentItemEditingEventOrigins.Undefined,
        }),
      );
    },
    self,
  );
};

const useTrackFirstItemChange = () => {
  const dispatch = useDispatch();
  const isSaving = useSelector((state) => !!state.contentApp.editedContentItemStatus.saving.size);
  const previousIsSaving = usePrevious(isSaving);
  const detectedSavingCount = useRef(0);

  useEffect(() => {
    if (isSaving && !previousIsSaving) {
      if (!detectedSavingCount.current) {
        dispatch(
          trackUserEvent(TrackedEvent.ContentItemEditing, {
            action: ContentItemEditingChangeAction.FirstSavingChanges,
            origin: ContentItemEditingEventOrigins.Undefined,
          }),
        );
      }

      detectedSavingCount.current++;
    }
  }, [isSaving, previousIsSaving]);
};
