import { RefinedNavigation } from '@kontent-ai/component-library/RefinedNavigation';
import { alphabetically, createCompare } from '@kontent-ai/utils';
import { FC, useContext } from 'react';
import { getContentInventoryTabsStructure } from '../../../applications/contentInventory/shared/utils/contentInventoryTabsStructure.ts';
import { getContentModelsTabsStructure } from '../../../applications/contentModels/shared/utils/contentModelsTabsStructure.ts';
import { isSitemapEnabled } from '../../../applications/contentModels/sitemap/utils/sitemapUtils.ts';
import { useGetEnvironmentSettingsMenuFeatureAvailability } from '../../../applications/environmentSettings/hooks/useGetEnvironmentSettingsMenuFeatureAvailability.ts';
import { convertAppNameToId } from '../../../applications/environmentSettings/utils/convertAppNameToId.ts';
import { environmentSettingsMenuStructure } from '../../../applications/environmentSettings/utils/environmentSettingsMenuStructure.tsx';
import { isItemVisible } from '../../../applications/environmentSettings/utils/environmentSettingsMenuStructureUtils.tsx';
import { useTabs } from '../../../applications/missionControl/components/MissionControlPage.tsx';
import { ProjectSettingsAppNames } from '../../../applications/projectSettings/root/constants/ProjectSettingsAppNames.ts';
import { isWebSpotlightEnabled as isWebSpotlightEnabledSelector } from '../../../applications/webSpotlight/selectors/webSpotlightSelectors.ts';
import { projectIdStorage } from '../../../localStorages/projectIdStorage.ts';
import { RefinedMainMenuProps } from '../../components/MainMenu/RefinedMainMenu.tsx';
import { RefinedNavigationToggleId } from '../../constants/RefinedNavigationToggleId.ts';
import { AppNames } from '../../constants/applicationNames.ts';
import {
  ApiKeyListingRoute,
  EnvironmentRouteParams,
  ProjectRouteParams,
  ProjectSettingsEnvironmentsRoute,
  ProjectSettingsGeneralRoute,
  WebSpotlightEntryRoute,
} from '../../constants/routePaths.ts';
import {
  MainNavigationContext,
  NavigationBarExpandedState,
} from '../../contexts/MainNavigationContext.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { getSelectedLanguageIdOrRouteMacro } from '../../selectors/getSelectedLanguageId.ts';
import { isLegacyWebhookConfigurationEnabled as isLegacyWebhookConfigurationEnabledSelector } from '../../selectors/isLegacyWebhookConfigurationEnabled.ts';
import { isAdminOnSubscription } from '../../selectors/subscriptionSelectors.ts';
import {
  getCurrentProject,
  getCurrentProjectPlan,
  getCurrentProjectSubscription,
  getProjectInfo,
} from '../../selectors/userProjectsInfoSelectors.ts';
import {
  DataUiAppName,
  DataUiElement,
  getDataUiElementAttribute,
  getDataUiNavAttribute,
  getDataUiObjectNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../utils/func/compose.ts';
import { getCurrentOrStorageEnvironmentId } from '../../utils/getCurrentOrStorageEnvironmentId.ts';
import { createCustomAppDetailLink } from '../../utils/routing/projectRoutingUtils.ts';
import { buildPath } from '../../utils/routing/routeTransitionUtils.ts';

type IProps = RefinedMainMenuProps & {
  readonly navigationState: NavigationBarExpandedState;
};

export const RefinedNavigationMenu: FC<IProps> = ({
  capability,
  userProjectInfo,
  projectEnvironmentRoutes,
  navigationState,
}) => {
  const environmentId = getCurrentOrStorageEnvironmentId(userProjectInfo.projectId);
  const projectContainerId = userProjectInfo.projectContainerId;

  const { expandedNavigationToggleIds, toggleNavigationToggle } = useContext(MainNavigationContext);

  const inventoryLinks = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageIdOrRouteMacro(s);
    return getContentInventoryTabsStructure(userProjectInfo, selectedLanguageId);
  });
  const contentModelsTabs = useSelector(
    compose(getContentModelsTabsStructure, isSitemapEnabled, getCurrentProjectSubscription),
  );
  const currentProjectPlan = useSelector((state) => getCurrentProjectPlan(state));
  const projectInfo = useSelector((state) =>
    getProjectInfo(state, getCurrentProject(state).projectId ?? String(projectIdStorage.load())),
  );
  const areLegacyWebhooksEnabled = useSelector(isLegacyWebhookConfigurationEnabledSelector);
  const isWebSpotlightEnabled = useSelector(isWebSpotlightEnabledSelector);
  const environmentSettingsMenuFeatureAvailability =
    useGetEnvironmentSettingsMenuFeatureAvailability(
      projectInfo,
      currentProjectPlan,
      areLegacyWebhooksEnabled,
      isWebSpotlightEnabled,
    );
  const filteredEnvironmentsSettingsTabs = environmentSettingsMenuStructure.filter((item) =>
    isItemVisible(item, capability, environmentSettingsMenuFeatureAvailability),
  );
  const areGeneralSettingsVisible = useSelector((s) =>
    isAdminOnSubscription(s, userProjectInfo.subscriptionId),
  );

  const missionControlStructure = useTabs();
  const customApps = useSelector((state) =>
    [...state.data.userCustomApps.customApps].sort(
      createCompare({
        compare: alphabetically,
        select: (customApp) => customApp.name,
      }),
    ),
  );

  return (
    <>
      <RefinedNavigation
        isVisuallyHidden={navigationState !== NavigationBarExpandedState.Minimized}
      >
        {projectEnvironmentRoutes.missionControl &&
          capability.canOneOf(projectEnvironmentRoutes.missionControl.requiredCapabilities) && (
            <RefinedNavigation.Icon iconName="Rocket" />
          )}
        {projectEnvironmentRoutes.webSpotlight &&
          capability.canOneOf(projectEnvironmentRoutes.webSpotlight.requiredCapabilities) && (
            <RefinedNavigation.Icon iconName="Earth" />
          )}
        {capability.canOneOf(projectEnvironmentRoutes.content.requiredCapabilities) && (
          <RefinedNavigation.Icon iconName="TwoSquaresWithLines" />
        )}
        {capability.canOneOf(projectEnvironmentRoutes.contentModels.requiredCapabilities) && (
          <RefinedNavigation.Icon iconName="Binder" />
        )}
        {projectEnvironmentRoutes.customApps &&
          capability.canOneOf(projectEnvironmentRoutes.customApps.requiredCapabilities) && (
            <RefinedNavigation.Icon iconName="PuzzlePlus" />
          )}
        {capability.canOneOf(projectEnvironmentRoutes.environmentSettings.requiredCapabilities) && (
          <RefinedNavigation.Icon iconName="Cogwheel" />
        )}
        {projectContainerId && <RefinedNavigation.Icon iconName="Cogwheels" />}
      </RefinedNavigation>
      <RefinedNavigation
        isVisuallyHidden={navigationState === NavigationBarExpandedState.Minimized}
      >
        {projectEnvironmentRoutes.missionControl &&
          capability.canOneOf(projectEnvironmentRoutes.missionControl.requiredCapabilities) && (
            <RefinedNavigation.Toggle
              iconName="Rocket"
              label={AppNames.MissionControl}
              isExpanded={expandedNavigationToggleIds.has(RefinedNavigationToggleId.MissionControl)}
              onIsExpandedChange={() =>
                toggleNavigationToggle(RefinedNavigationToggleId.MissionControl)
              }
              {...getDataUiNavAttribute(DataUiAppName.MissionControl)}
            >
              {missionControlStructure.map((link) => (
                <RefinedNavigation.SubLink
                  label={link.label}
                  to={link.to}
                  key={link.id}
                  id={link.id}
                  {...getDataUiObjectNameAttribute(link.id)}
                />
              ))}
            </RefinedNavigation.Toggle>
          )}
        {projectEnvironmentRoutes.webSpotlight &&
          capability.canOneOf(projectEnvironmentRoutes.webSpotlight.requiredCapabilities) && (
            <RefinedNavigation.Link
              iconName="Earth"
              to={buildPath<EnvironmentRouteParams>(WebSpotlightEntryRoute, {
                projectId: environmentId,
              })}
              label={AppNames.WebSpotlight}
              {...getDataUiNavAttribute(DataUiAppName.WebSpotlight)}
            />
          )}
        {capability.canOneOf(projectEnvironmentRoutes.content.requiredCapabilities) && (
          <RefinedNavigation.Toggle
            iconName="TwoSquaresWithLines"
            label={AppNames.Content}
            isExpanded={expandedNavigationToggleIds.has(RefinedNavigationToggleId.Content)}
            onIsExpandedChange={() => toggleNavigationToggle(RefinedNavigationToggleId.Content)}
          >
            {inventoryLinks.map((link) => (
              <RefinedNavigation.SubLink
                key={link.id}
                id={link.id}
                label={link.label}
                to={link.path}
                {...link.dataUiAttributes}
              />
            ))}
          </RefinedNavigation.Toggle>
        )}
        {capability.canOneOf(projectEnvironmentRoutes.contentModels.requiredCapabilities) && (
          <RefinedNavigation.Toggle
            iconName="Binder"
            label={AppNames.ContentModels}
            isExpanded={expandedNavigationToggleIds.has(RefinedNavigationToggleId.ContentModel)}
            onIsExpandedChange={() =>
              toggleNavigationToggle(RefinedNavigationToggleId.ContentModel)
            }
            {...getDataUiNavAttribute(DataUiAppName.ContentModels)}
          >
            {contentModelsTabs
              .filter((tab) => capability.can(tab.requiredCapability))
              .map((link, i) => (
                <RefinedNavigation.SubLink
                  key={i}
                  id={link.linkRoute}
                  label={link.tabText}
                  to={buildPath<EnvironmentRouteParams>(link.linkRoute, {
                    projectId: environmentId,
                  })}
                />
              ))}
          </RefinedNavigation.Toggle>
        )}
        {projectEnvironmentRoutes.customApps &&
          capability.canOneOf(projectEnvironmentRoutes.customApps.requiredCapabilities) && (
            <RefinedNavigation.Toggle
              iconName="PuzzlePlus"
              label={AppNames.CustomApps}
              isExpanded={expandedNavigationToggleIds.has(RefinedNavigationToggleId.CustomApps)}
              onIsExpandedChange={() =>
                toggleNavigationToggle(RefinedNavigationToggleId.CustomApps)
              }
              {...getDataUiNavAttribute(DataUiAppName.CustomApps)}
            >
              {customApps.map((link) => (
                <RefinedNavigation.SubLink
                  key={link.id}
                  label={link.name}
                  to={createCustomAppDetailLink({
                    projectContainerId: environmentId,
                    customAppId: link.id,
                  })}
                />
              ))}
            </RefinedNavigation.Toggle>
          )}
        {capability.canOneOf(projectEnvironmentRoutes.environmentSettings.requiredCapabilities) && (
          <RefinedNavigation.Toggle
            iconName="Cogwheel"
            label={AppNames.EnvironmentSettings}
            isExpanded={expandedNavigationToggleIds.has(
              RefinedNavigationToggleId.EnvironmentSettings,
            )}
            onIsExpandedChange={() =>
              toggleNavigationToggle(RefinedNavigationToggleId.EnvironmentSettings)
            }
            {...getDataUiNavAttribute(DataUiAppName.ProjectSettings)}
          >
            {filteredEnvironmentsSettingsTabs.map((item) => (
              <RefinedNavigation.SubLink
                label={item.text}
                to={buildPath<EnvironmentRouteParams>(item.routePath, {
                  projectId: environmentId,
                })}
                id={convertAppNameToId(item.text)}
                key={convertAppNameToId(item.text)}
                {...item.dataAttributes}
                {...getDataUiElementAttribute(DataUiElement.SettingGroupItem)}
              />
            ))}
          </RefinedNavigation.Toggle>
        )}
        {projectContainerId && (
          <RefinedNavigation.Toggle
            iconName="Cogwheels"
            label={AppNames.ProjectSettings}
            isExpanded={expandedNavigationToggleIds.has(RefinedNavigationToggleId.ProjectSettings)}
            onIsExpandedChange={() =>
              toggleNavigationToggle(RefinedNavigationToggleId.ProjectSettings)
            }
            {...getDataUiNavAttribute(DataUiAppName.ProjectSettings)}
          >
            {areGeneralSettingsVisible && (
              <RefinedNavigation.SubLink
                label={ProjectSettingsAppNames.General}
                to={buildPath<ProjectRouteParams>(ProjectSettingsGeneralRoute, {
                  projectContainerId,
                })}
                {...getDataUiNavAttribute(DataUiAppName.General)}
              />
            )}
            <RefinedNavigation.SubLink
              label={ProjectSettingsAppNames.Environments}
              to={buildPath<ProjectRouteParams>(ProjectSettingsEnvironmentsRoute, {
                projectContainerId,
              })}
              {...getDataUiNavAttribute(DataUiAppName.Environments)}
            />
            <RefinedNavigation.SubLink
              label={ProjectSettingsAppNames.ApiKeyListing}
              to={buildPath<ProjectRouteParams>(ApiKeyListingRoute, { projectContainerId })}
              {...getDataUiNavAttribute(DataUiAppName.ApiKeys)}
            />
          </RefinedNavigation.Toggle>
        )}
      </RefinedNavigation>
    </>
  );
};
