import React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { getCurrentProject } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../../_shared/utils/languageUtils.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  DecoratedYourContentItem,
  YourContentItem,
} from '../../../../../data/models/listingContentItems/YourContentItem.ts';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';
import { decorateYourContentItems } from '../../tasksAssignedToYou/utils/yourContentItemDecorationUtils.ts';
import { RecentlyEditedByYouWidgetDetail } from '../components/RecentlyEditedByYouWidgetDetail.tsx';
import { RecentlyEditedByYouWidgetDetailRow } from '../components/RecentlyEditedByYouWidgetDetailRow.tsx';

type RecentlyEditedByYouWidgetDetailProps = React.PropsWithChildren<{
  readonly ariaLabelledBy: string;
  readonly isPreviewOpen: boolean;
  readonly onItemClick?: () => void;
  readonly dataState: WidgetListingState;
  readonly recentlyEditedItems: ReadonlyArray<YourContentItem>;
}>;

export const RecentlyEditedByYouWidgetDetailContainer: React.FC<
  RecentlyEditedByYouWidgetDetailProps
> = ({ ariaLabelledBy, isPreviewOpen, onItemClick, dataState, recentlyEditedItems }) => {
  const loadingDependenciesFinished = useSelector(
    (s) =>
      s.data.contentTypes.loadingStatus === LoadingStatus.Loaded &&
      s.data.languages.byIdLoadingStatus === LoadingStatus.Loaded,
  );

  const { pathname } = useLocation();
  const contentTypes = useSelector((s) => s.data.contentTypes.byId);
  const languages = useSelector((s) => s.data.languages.byId);
  const viewableLanguages = useSelector((s) =>
    getAllActiveLanguagesForCurrentUserInAnyCollection(
      s.data.user,
      getCurrentProject(s).projectId,
      s.data.languages,
      Capability.ViewContent,
    ),
  );

  const decoratedRecentlyEditedItems: ReadonlyArray<DecoratedYourContentItem> =
    loadingDependenciesFinished
      ? decorateYourContentItems(recentlyEditedItems, languages, viewableLanguages, contentTypes)
      : [];

  return (
    <RecentlyEditedByYouWidgetDetail
      state={loadingDependenciesFinished ? dataState : WidgetListingState.Loading}
      ariaLabelledBy={ariaLabelledBy}
    >
      {decoratedRecentlyEditedItems.map((item) => {
        const path = getContentItemPath(pathname, item.id.itemId, isPreviewOpen, item.id.variantId);

        return (
          <RecentlyEditedByYouWidgetDetailRow
            key={stringifyContentItemId(item.id)}
            onItemClick={onItemClick}
            pathToItem={path}
            recentlyEditedItem={item}
          />
        );
      })}
    </RecentlyEditedByYouWidgetDetail>
  );
};
