import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { LinkIsInvalidMessage } from '../../constants/uiConstants.ts';
import { variantService } from '../../services/variantService.ts';
import { handleUnsuccessfulRedirect } from '../smartLinkActions.ts';
import { editItemVariant } from '../thunkSmartLinkActions.ts';

type Params = {
  readonly projectId: Uuid;
  readonly variantCodename: string;
  readonly itemId: Uuid;
  readonly isPreviewOpen: boolean;
};

export const editItemVariantCodenameByItemId =
  (
    { projectId, variantCodename, itemId, isPreviewOpen }: Params,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch) => {
    const variantId = await variantService.getVariantIdFromCodename(
      variantCodename,
      projectId,
      abortSignal,
    );

    if (variantId) {
      await dispatch(
        editItemVariant(
          {
            projectId,
            variantId,
            path: [{ itemId }],
            isPreviewOpen,
          },
          abortSignal,
        ),
      );
    } else {
      dispatch(handleUnsuccessfulRedirect(null, LinkIsInvalidMessage, null));
    }
  };
