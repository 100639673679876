import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { memoize } from '@kontent-ai/memoization';
import { useEffect } from 'react';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { useDataSelector } from '../../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { INonLocalizableElementsWarningType } from '../../../../../../_shared/models/TrackUserEventData.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import {
  isScheduleToPublishWorkflowStepSelected,
  isScheduledToPublishWorkflowStep,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import { isVariantPublished } from '../../../../../../_shared/utils/contentItemVariantUtils.ts';
import {
  ContentItemVariantWithLanguageName,
  getContentItemVariantsWithLanguageNames,
} from '../../../../../../_shared/utils/getContentItemVariantsWithLanguageNames.tsx';
import { getAllLanguagesWithDefaultSuffix } from '../../../../../../_shared/utils/languageUtils.ts';
import { Languages } from '../../../../../../data/models/languages/Language.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { addDefaultLanguageSuffix } from '../../../../../environmentSettings/localization/utils/languageUtils.ts';
import { IContentItemVariantReference } from '../../../../../itemEditor/models/contentItem/ContentItemVariantReference.ts';
import { getEditedContentItemType } from '../../../../../itemEditor/selectors/getEditedContentItemType.ts';
import { DependentScheduledVariantsFriendlyWarning } from '../../../components/cancelConfirmation/DependentScheduledVariantsFriendlyWarning.tsx';

const getDependentScheduledVariants = memoize.weak(
  (
    variants: Immutable.Map<Uuid, IContentItemVariantReference>,
    languages: Languages,
    projectId: Uuid,
    isPreviewOpen: boolean,
  ): ReadonlyArray<ContentItemVariantWithLanguageName> => {
    const contentItemVariantsWithLanguageNames = getContentItemVariantsWithLanguageNames(
      variants,
      languages,
      projectId,
      isPreviewOpen,
    );

    return contentItemVariantsWithLanguageNames.filter((variant) => {
      return (
        variant.id.variantId !== DefaultVariantId &&
        !!variant.assignment.scheduledToPublishAt &&
        isScheduledToPublishWorkflowStep(variant.assignment.workflowStatus)
      );
    });
  },
);

export const CancelSchedulePublishFriendlyWarning = () => {
  const dispatch = useDispatch();
  const defaultLanguageName = useDataSelector(
    (data) => addDefaultLanguageSuffix(data.languages.defaultLanguage).name,
  );
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const shouldDisplayFriendlyWarning = useSelector((s): boolean => {
    const isEditingDefaultVariant = getSelectedLanguageId(s) === DefaultVariantId;
    const isDefaultVariantPublished = isVariantPublished(s.contentApp.editedContentItemVariant);
    const isDefaultVariantScheduled = isScheduleToPublishWorkflowStepSelected(
      s.contentApp.editedContentItemVariant?.assignment,
    );

    if (!isEditingDefaultVariant || isDefaultVariantPublished || !isDefaultVariantScheduled) {
      return false;
    }

    return getEditedContentItemType(s)?.contentElements.some((el) => el.isNonLocalizable) ?? false;
  });

  const dependentScheduledVariants = useSelector((s) =>
    getDependentScheduledVariants(
      s.contentApp.contentItemVariants,
      getAllLanguagesWithDefaultSuffix(s.data.languages),
      s.sharedApp.currentProjectId,
      isLivePreviewPreferred,
    ),
  );

  useEffect(() => {
    if (shouldDisplayFriendlyWarning && dependentScheduledVariants.length) {
      dispatch(
        trackUserEvent(TrackedEvent.NonLocalizableElementsWarningDisplayed, {
          type: INonLocalizableElementsWarningType.CancelDefaultVariantSchedulePublishFriendlyWarning,
        }),
      );
    }
  }, [shouldDisplayFriendlyWarning, dependentScheduledVariants.length]);

  if (!shouldDisplayFriendlyWarning || dependentScheduledVariants.length === 0) {
    return null;
  }

  return (
    <Box marginTop={Spacing.XL}>
      <DependentScheduledVariantsFriendlyWarning
        defaultLanguageName={defaultLanguageName}
        dependentVariants={dependentScheduledVariants}
      />
    </Box>
  );
};
