import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  ContentItemPreviewRoute,
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
  ContentItemsRoute,
  ContentItemsRouteParams,
} from '../../../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../../../_shared/hooks/useThunkPromise.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  expandWebSpotlightNode,
  loadContentItemNavigationTree,
  selectWebSpotlightNode,
} from '../../../../../../webSpotlight/actions/thunkWebSpotlightActions.ts';
import { webSpotlightNodeCollapsed } from '../../../../../../webSpotlight/actions/webSpotlightActions.ts';
import { NodeId } from '../../../../../../webSpotlight/types/nodeId.type.ts';
import { NavigationTree as NavigationTreeComponent } from '../components/NavigationTree.tsx';
import { useNavigationTree } from '../context/NavigationTreeContext.tsx';
import { useRootItem } from '../context/RootItemContext.tsx';

export const NavigationTree = () => {
  const editedContentItem = useSelector((state) => state.contentApp.editedContentItem);
  const listingContentItems = useSelector((state) => state.data.listingContentItems.byId);
  const expandedNodesData = useSelector((state) => state.webSpotlightApp.expandedNodesData);
  const focusedNodeId = useSelector((state) => state.webSpotlightApp.focusedNodeId);
  const nodesBeingLoaded = useSelector((state) => state.webSpotlightApp.nodesBeingLoaded);
  const subpagesById = useSelector((state) => state.webSpotlightApp.subpagesById);

  const { rootItemId } = useRootItem();
  const { isTreeAvailable, isTreeOpened } = useNavigationTree();
  const parentItemIds = useParentContentItemIds();

  useThunkPromise(loadContentItemNavigationTree, parentItemIds, {
    canRun: isTreeAvailable,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const onNodeCollapsed = useCallback(
    (nodeId: NodeId) => dispatch(webSpotlightNodeCollapsed(nodeId)),
    [],
  );
  const onNodeExpanded = useCallback(
    (nodeId: NodeId, itemId: Uuid) =>
      dispatch(
        expandWebSpotlightNode({
          itemId,
          nodeId,
        }),
      ),
    [],
  );

  const onNodeSelected = useCallback(
    (nodeId: NodeId, itemId: Uuid, path: string) => {
      history.push(path);
      dispatch(
        selectWebSpotlightNode({
          itemId,
          nodeId,
        }),
      );
    },
    [history],
  );

  const currentPath = history.location.pathname;
  const matchParams = matchPath<ContentItemsRouteParams>(currentPath, ContentItemsRoute);
  if (!matchParams) {
    return null;
  }

  const rootPath = buildPath<ContentItemsRouteParams>(ContentItemsRoute, {
    app: ContentItemsAppRouteSegment.Content,
    projectId: matchParams.projectId,
    spaceId: matchParams.spaceId,
    variantId: matchParams.variantId,
  });

  const previewMatchParams = matchPath<ContentItemsRouteParams>(
    currentPath,
    ContentItemPreviewRoute,
  );

  return (
    isTreeOpened &&
    rootItemId && (
      <NavigationTreeComponent
        contentItems={listingContentItems}
        currentPath={currentPath}
        editedContentItem={editedContentItem}
        expandedNodesData={expandedNodesData}
        focusedNodeId={focusedNodeId}
        nodesBeingLoaded={nodesBeingLoaded}
        onNodeCollapsed={onNodeCollapsed}
        onNodeExpanded={onNodeExpanded}
        onNodeSelected={onNodeSelected}
        rootItemId={rootItemId}
        rootPath={rootPath}
        selectedLanguageId={matchParams.variantId}
        isPreviewOpen={!!previewMatchParams}
        subpagesById={subpagesById}
      />
    )
  );
};

const useParentContentItemIds = (): UuidArray => {
  const location = useLocation();
  const matchParams = matchPath<ContentItemRouteParams<string>>(
    location.pathname,
    ContentItemRoute,
  );

  const itemIds = matchParams ? parseContentItemIds(matchParams.contentItemIds) : [];
  const { rootItemId } = useRootItem();

  return useMemo(() => {
    if (!rootItemId) {
      return [];
    }

    const parentItemIds = itemIds.length > 1 ? itemIds.slice(0, -1) : itemIds;
    // KCL-13782 It won't be needed when the root item in the route is replaced upon a space change.
    return [rootItemId, ...parentItemIds.slice(1)];
  }, [itemIds, rootItemId]);
};
