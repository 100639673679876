import React from 'react';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { CalendarEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { CalendarToolbar as CalendarToolbarComponent } from '../../components/Calendar/CalendarToolbar.tsx';
import { NavigateAction } from '../../constants/NavigateAction.ts';

type CalendarToolbarOwnProps = {
  readonly label: string;
  readonly onNavigate: (action: NavigateAction) => void;
  readonly isFilterVisible: boolean;
  readonly onToggleFilter: () => void;
};

export const CalendarToolbar: React.FC<CalendarToolbarOwnProps> = (props) => {
  const isCalendarViewLoaded = useSelector(
    (state) => state.calendarApp.itemsLoadingStatus === LoadingStatus.Loaded,
  );
  const calendarView = useSelector((state) => state.calendarApp.calendarView);

  const dispatch = useDispatch();

  const onPrev = () =>
    dispatch(
      trackUserEvent(TrackedEvent.Calendar, {
        action: CalendarEventTypes.PreviousMonthSelected,
      }),
    );

  const onNext = () =>
    dispatch(
      trackUserEvent(TrackedEvent.Calendar, {
        action: CalendarEventTypes.NextMonthSelected,
      }),
    );

  return (
    <CalendarToolbarComponent
      {...props}
      calendarView={calendarView}
      isCurrentMonthLoaded={isCalendarViewLoaded}
      onNext={onNext}
      onPrev={onPrev}
    />
  );
};

CalendarToolbar.displayName = 'CalendarToolbar';
