import { dismissContextualHelp } from '../../../../../_shared/actions/thunkSharedActions.ts';
import { ContextualHelp } from '../../../../../_shared/components/ContextualHelp/ContextualHelp.tsx';
import { CreateNewVersion } from '../../../../../_shared/constants/itemActions.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getSampleProjectType } from '../../../../../_shared/models/utils/quickstartUtils.ts';
import { ContextualHelpType } from '../../../../../data/models/contextualHelp/ContextualHelpTypeEnum.ts';
import { WorkflowStepAction } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { SampleProjectType } from '../../../../projects/constants/sampleProjectType.ts';

const bulletPoints = [
  () => (
    <>
      Add new or edit existing content and <b>publish</b> your changes.
    </>
  ),
  () => (
    <>
      <b>Discuss</b> and <b>assign team</b> members to create the best content.
    </>
  ),
];

const bulletPointsForPublished = [
  ...bulletPoints,
  () => (
    <>
      This content is published. <b>{CreateNewVersion}</b> to be able to edit it.
    </>
  ),
];

export const ContentItemEditorContextualHelp = () => {
  const dispatch = useDispatch();

  const shouldShow = useSelector(
    (s) =>
      getSampleProjectType(s.sharedApp.projectProperties) === SampleProjectType.Kickstart &&
      s.sharedApp.userProperties.contextualHelpSettings.get(ContextualHelpType.ContentItem, false),
  );

  const isCurrentItemPublished = useSelector(
    (s) =>
      s.contentApp.editedContentItemVariant?.assignment.workflowStatus.action ===
      WorkflowStepAction.Publish,
  );

  return (
    <ContextualHelp
      bulletPoints={isCurrentItemPublished ? bulletPointsForPublished : bulletPoints}
      onDismiss={() => dispatch(dismissContextualHelp(ContextualHelpType.ContentItem))}
      shouldShow={shouldShow}
      title="You’re in a content item"
    />
  );
};
