import { dismissContextualHelp } from '../../../../_shared/actions/thunkSharedActions.ts';
import { ContextualHelp } from '../../../../_shared/components/ContextualHelp/ContextualHelp.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getSampleProjectType } from '../../../../_shared/models/utils/quickstartUtils.ts';
import { ContextualHelpType } from '../../../../data/models/contextualHelp/ContextualHelpTypeEnum.ts';
import { SampleProjectType } from '../../../projects/constants/sampleProjectType.ts';
import { FullTextSearchStatus } from '../reducers/IContentInventoryStoreState.type.ts';

const bulletPoints = [
  () => 'You can see content items which hold your content.',
  () => (
    <>
      Find your content by <b>filtering by name</b> or narrowing your list with other options.
    </>
  ),
];

const bulletPointsForFullTextSearch = [
  () => 'You can see content items that hold your content.',
  () => (
    <>
      Find your items by <b>searching their names or content</b>.
    </>
  ),
  () => 'Narrow your list with filtering options.',
];

export const ContentItemsContextualHelp = () => {
  const dispatch = useDispatch();
  const isFullTextSearch = useSelector(
    (s) => s.contentInventory.fullTextSearchStatus === FullTextSearchStatus.Enabled,
  );

  const shouldShow = useSelector(
    (s) =>
      getSampleProjectType(s.sharedApp.projectProperties) === SampleProjectType.Kickstart &&
      s.sharedApp.userProperties.contextualHelpSettings.get(ContextualHelpType.ContentItems, false),
  );

  return (
    <ContextualHelp
      title="This is the content inventory"
      onDismiss={() => dispatch(dismissContextualHelp(ContextualHelpType.ContentItems))}
      bulletPoints={isFullTextSearch ? bulletPointsForFullTextSearch : bulletPoints}
      shouldShow={shouldShow}
      callToAction={
        <>
          Open an existing item or <b>create a new</b> one.
        </>
      }
    />
  );
};
