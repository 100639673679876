import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { useRedirectPropsWithSameSearch } from '../../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  AssetTypesRoute,
  ContentTypeSnippetsRoute,
  ContentTypesRoute,
  CreateNewContentTypeRoute,
  CreateNewContentTypeSnippetsRoute,
  CreateNewTaxonomyGroupRoute,
  EditContentTypeRoute,
  EditContentTypeSnippetRoute,
  EditContentTypeSnippetRouteParams,
  EditTaxonomyGroupRoute,
  EditTaxonomyGroupRouteParams,
  EnvironmentRouteParams,
  SitemapRoute,
  TaxonomyGroupsRoute,
} from '../../../../_shared/constants/routePaths.ts';
import { AuthorizedSection } from '../../../../_shared/containers/routing/AuthorizedSection.tsx';
import {
  IRouteContext,
  RouteContext,
} from '../../../../_shared/containers/routing/RouteContext.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { AssetTypeEditor } from '../../assetTypes/containers/AssetTypeEditor.tsx';
import { ContentTypeCreator } from '../../contentTypes/containers/ContentTypeCreator.tsx';
import { ContentTypeEditor } from '../../contentTypes/containers/ContentTypeEditor.tsx';
import { ContentTypeEditorContextualHelp } from '../../contentTypes/containers/ContentTypeEditorContextualHelp.tsx';
import { ContentTypeListing } from '../../contentTypes/containers/ContentTypeListing.tsx';
import { ContentTypeListingContextualHelp } from '../../contentTypes/containers/ContentTypeListingContextualHelp.tsx';
import { SitemapListing } from '../../sitemap/containers/SitemapListing.tsx';
import { ContentTypeSnippetCreator } from '../../snippets/containers/ContentTypeSnippetCreator.tsx';
import { ContentTypeSnippetEditor } from '../../snippets/containers/ContentTypeSnippetEditor.tsx';
import { ContentTypeSnippetListing } from '../../snippets/containers/ContentTypeSnippetListing.tsx';
import { TaxonomyGroupCreator } from '../../taxonomy/containers/TaxonomyGroupCreator.tsx';
import { TaxonomyGroupEditor } from '../../taxonomy/containers/TaxonomyGroupEditor.tsx';
import { TaxonomyGroupListing } from '../../taxonomy/containers/TaxonomyGroupListing.tsx';
import { ContentModelsTabInfo } from '../utils/contentModelsTabsStructure.ts';

type Props = {
  readonly firstAllowedTab: ContentModelsTabInfo;
  readonly isSitemapEnabled: boolean;
  readonly projectId: Uuid;
};

export const ContentModels: React.FC<Props> = ({
  firstAllowedTab,
  isSitemapEnabled,
  projectId,
}) => {
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();

  return (
    <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.ContentModels)}>
      <HtmlPageTitle appName={AppNames.ContentModels} />
      <Switch>
        <Route path={EditContentTypeRoute}>
          <AuthorizedSection
            appName={AppNames.ContentTypes}
            requiresOneOfCapabilities={[Capability.ConfigureContentTypes]}
          >
            <ContentTypeEditor />
            <ContentTypeEditorContextualHelp />
          </AuthorizedSection>
        </Route>
        <Route path={CreateNewContentTypeRoute}>
          <AuthorizedSection
            appName={AppNames.ContentTypes}
            requiresOneOfCapabilities={[Capability.ConfigureContentTypes]}
          >
            <ContentTypeCreator />
            <ContentTypeEditorContextualHelp />
          </AuthorizedSection>
        </Route>
        <Route path={EditContentTypeSnippetRoute}>
          <RouteContext>
            {({
              match: contentTypeSnippetMatch,
            }: IRouteContext<EditContentTypeSnippetRouteParams>) => (
              <AuthorizedSection
                appName={AppNames.ContentTypeSnippets}
                requiresOneOfCapabilities={[Capability.ConfigureContentTypes]}
              >
                <ContentTypeSnippetEditor
                  key={`snippet-editing-${contentTypeSnippetMatch.params.contentTypeSnippetId}`}
                />
              </AuthorizedSection>
            )}
          </RouteContext>
        </Route>
        <Route path={CreateNewContentTypeSnippetsRoute}>
          <AuthorizedSection
            appName={AppNames.ContentTypeSnippets}
            requiresOneOfCapabilities={[Capability.ConfigureContentTypes]}
          >
            <ContentTypeSnippetCreator />
          </AuthorizedSection>
        </Route>
        <Route path={EditTaxonomyGroupRoute}>
          <RouteContext>
            {({ match: taxonomyGroupMatch }: IRouteContext<EditTaxonomyGroupRouteParams>) => (
              <AuthorizedSection
                appName={AppNames.Taxonomies}
                requiresOneOfCapabilities={[Capability.ConfigureTaxonomy]}
              >
                <TaxonomyGroupEditor
                  key={`taxonomy-group-editing-${taxonomyGroupMatch.params.taxonomyGroupId}`}
                />
              </AuthorizedSection>
            )}
          </RouteContext>
        </Route>
        <Route path={CreateNewTaxonomyGroupRoute}>
          <AuthorizedSection
            appName={AppNames.Taxonomies}
            requiresOneOfCapabilities={[Capability.ConfigureTaxonomy]}
          >
            <TaxonomyGroupCreator />
          </AuthorizedSection>
        </Route>
        <Route path={ContentTypesRoute}>
          <AuthorizedSection
            appName={AppNames.ContentTypes}
            requiresOneOfCapabilities={[Capability.ConfigureContentTypes]}
          >
            <ContentTypeListing />
            <ContentTypeListingContextualHelp />
          </AuthorizedSection>
        </Route>
        <Route path={ContentTypeSnippetsRoute}>
          <AuthorizedSection
            appName={AppNames.ContentTypeSnippets}
            requiresOneOfCapabilities={[Capability.ConfigureContentTypes]}
          >
            <ContentTypeSnippetListing />
          </AuthorizedSection>
        </Route>
        {isSitemapEnabled && (
          <Route path={SitemapRoute}>
            <AuthorizedSection
              appName={AppNames.Sitemap}
              requiresOneOfCapabilities={[Capability.ConfigureSitemap]}
            >
              <SitemapListing />
            </AuthorizedSection>
          </Route>
        )}
        <Route path={TaxonomyGroupsRoute}>
          <AuthorizedSection
            appName={AppNames.Taxonomies}
            requiresOneOfCapabilities={[Capability.ConfigureTaxonomy]}
          >
            <TaxonomyGroupListing />
          </AuthorizedSection>
        </Route>
        <Route path={AssetTypesRoute}>
          <AuthorizedSection
            appName={AppNames.AssetType}
            requiresOneOfCapabilities={[Capability.ConfigureContentTypes]}
          >
            <AssetTypeEditor />
          </AuthorizedSection>
        </Route>
        <Route>
          <Redirect
            {...getRedirectPropsWithSameSearch({
              to: buildPath<EnvironmentRouteParams>(firstAllowedTab.linkRoute, { projectId }),
            })}
          />
        </Route>
      </Switch>
    </div>
  );
};

ContentModels.displayName = 'ContentModels';
