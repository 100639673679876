import React from 'react';
import {
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../../../../_shared/constants/routePaths.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { hasCapabilityInLanguage } from '../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { getCannotViewItemMessage } from '../../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import {
  buildPath,
  pickContentItemRoute,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { CannotViewItemMessage } from '../../../../../contentInventory/content/constants/cannotViewMessages.ts';
import {
  ILinkedItemSharedProps,
  LinkedItem,
} from '../../../components/typeElements/individualTypeElements/linkedItems/LinkedItem.tsx';
import { IDefaultValueLinkedItem } from '../../../reducers/IContentModelsAppStoreState.type.ts';

interface IElementLinkedItemProps extends ILinkedItemSharedProps {
  readonly contentItemId: Uuid;
  readonly elementId: Uuid;
}

const getContentItemPath = (
  state: IStore,
  contentItemId: Uuid,
  contentItem: IDefaultValueLinkedItem | undefined,
  isPreviewOpen: boolean,
): string | undefined =>
  contentItem &&
  buildPath<ContentItemRouteParams<UuidArray>>(pickContentItemRoute(isPreviewOpen), {
    app: ContentItemsAppRouteSegment.Content,
    projectId: state.sharedApp.currentProjectId,
    variantId: DefaultVariantId,
    spaceId: undefined,
    contentItemIds: [contentItemId],
  });

const getCannotViewDisabledMessage = (
  canViewDefaultLanguageItems: boolean,
  contentItem: IDefaultValueLinkedItem | undefined,
): string | undefined => {
  if (!canViewDefaultLanguageItems) {
    return CannotViewItemMessage;
  }

  return contentItem && getCannotViewItemMessage(contentItem);
};

export const ElementLinkedItem: React.FC<IElementLinkedItemProps> = ({
  contentItemId,
  elementId,
  ...sharedProps
}) => {
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const contentItem = useSelector((state) =>
    state.contentModelsApp.typeEditor.linkedItemsDefaultValueReferences.find(
      (item) => item.itemId === contentItemId,
    ),
  );
  const contentType = useSelector(
    (state) => contentItem && state.data.contentTypes.byId.get(contentItem.typeId),
  );
  const canViewDefaultLanguageItems = useSelector((s) =>
    hasCapabilityInLanguage(s, Capability.ViewContent, DefaultVariantId),
  );
  const contentItemPath = useSelector((s) =>
    getContentItemPath(s, contentItemId, contentItem, isLivePreviewPreferred),
  );

  const canCreate =
    !!contentItem && hasActiveVariantCapability(ActiveCapabilityType.CreateContent, contentItem);
  const cannotViewDisabledMessage = getCannotViewDisabledMessage(
    canViewDefaultLanguageItems,
    contentItem,
  );

  return (
    <LinkedItem
      canCreate={canCreate}
      cannotViewDisabledMessage={cannotViewDisabledMessage}
      canViewDefaultLanguageItems={canViewDefaultLanguageItems}
      contentItem={contentItem}
      contentItemId={contentItemId}
      contentItemPath={contentItemPath}
      contentType={contentType}
      {...sharedProps}
    />
  );
};

ElementLinkedItem.displayName = 'ElementLinkedItemContainer';
