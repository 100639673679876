export enum RefinedNavigationToggleId {
  Projects = 'projects',
  MissionControl = 'mission-control',
  Content = 'content-and-assets',
  ContentModel = 'content-model',
  CustomApps = 'custom-apps',
  EnvironmentSettings = 'environment-settings',
  ProjectSettings = 'project-settings',
  MySubscriptions = 'my-subscriptions',
}
