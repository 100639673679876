import React, { memo, useContext } from 'react';
import {
  isAiChangeToneEnabled,
  isAiCreateTitleEnabled,
  isAiImproveContentEnabled,
  isAiMakeShorterEnabled,
  isAiMatchWritingStyleEnabled,
  isAiReviewEnabled,
  isAiSummarizeEnabled,
  isAnyAskAiFeatureEnabled,
  isInlineAiEnabled,
} from '../../../../_shared/selectors/aiSelectors.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ReviewByGuidelinesPlugin } from '../../../../paperModels/aiReview/richText/plugins/ReviewByGuidelines/ReviewByGuidelinesPlugin.tsx';
import {
  EditorProps,
  emptyPluginComposition,
  useEditor,
  useOptionalPlugin,
  usePluginComposition,
} from '../../editorCore/Editor.composition.tsx';
import { AiMenuPlugin } from '../../plugins/ai/AiMenuPlugin.tsx';
import { AiStylesPlugin } from '../../plugins/ai/AiStylesPlugin.tsx';
import { ChangeTonePlugin } from '../../plugins/ai/actions/ChangeTone/ChangeTonePlugin.tsx';
import { MatchWritingStyleOfItemPlugin } from '../../plugins/ai/actions/ChangeTone/MatchWritingStyleOfItem/MatchWritingStyleOfItemPlugin.tsx';
import { CreateTitlePlugin } from '../../plugins/ai/actions/CreateTitle/CreateTitlePlugin.tsx';
import { ImproveContentPlugin } from '../../plugins/ai/actions/ImproveContent/ImproveContentPlugin.tsx';
import { MakeShorterPlugin } from '../../plugins/ai/actions/MakeShorter/MakeShorterPlugin.tsx';
import { SummarizePlugin } from '../../plugins/ai/actions/Summarize/SummarizePlugin.tsx';
import { TranslateContentPlugin } from '../../plugins/ai/actions/TranslateContent/TranslateContentPlugin.tsx';
import { AiTranslationsContext } from '../../plugins/ai/actions/TranslateContent/contexts/AiTranslationsContext.tsx';
import { ApiLimitationsPlugin } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { SimpleTextEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { CompositionModeAdjustmentsPlugin } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { FocusPlugin } from '../../plugins/behavior/FocusPlugin.tsx';
import { ForceSelectionOnBlurPlugin } from '../../plugins/behavior/ForceSelectionOnBlurPlugin.tsx';
import { ForceSingleUnstyledBlockPlugin } from '../../plugins/behavior/ForceSingleUnstyledBlockPlugin.tsx';
import { LockEditorPlugin } from '../../plugins/behavior/LockEditorPlugin.tsx';
import { OnChangePlugin } from '../../plugins/behavior/OnChangePlugin.tsx';
import { SpellCheckPlugin } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { ClipboardPlugin } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { CommentsPlugin } from '../../plugins/comments/CommentsPlugin.tsx';
import {
  CustomInputHandlingPlugin,
  EnterKeyBehavior,
} from '../../plugins/customInputHandling/CustomInputHandlingPlugin.tsx';
import { DraftJsPlugin } from '../../plugins/draftJs/DraftJsPlugin.tsx';
import { EntityApiPlugin } from '../../plugins/entityApi/EntityApiPlugin.tsx';
import { InlineAiPlugin } from '../../plugins/inlineAi/InlineAiPlugin.tsx';
import { KeyboardShortcutsPlugin } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { TextApiPlugin } from '../../plugins/textApi/TextApiPlugin.tsx';
import { BlockToolbarPlugin } from '../../plugins/toolbars/BlockToolbarPlugin.tsx';
import { InlineToolbarPlugin } from '../../plugins/toolbars/InlineToolbarPlugin.tsx';
import { UndoRedoPlugin } from '../../plugins/undoRedo/UndoRedoPlugin.tsx';
import { PlaceholderPlugin } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { StylesPlugin } from '../../plugins/visuals/StylesPlugin.tsx';
import { WrapperPlugin } from '../../plugins/visuals/WrapperPlugin.tsx';
import { SimpleMultilineTextInputStatusPlugin } from './plugins/SimpleMultilineTextInputStatusPlugin.tsx';

const useDefaultComposition = () =>
  usePluginComposition(
    emptyPluginComposition,
    DraftJsPlugin,
    PlaceholderPlugin,
    WrapperPlugin,
    CompositionModeAdjustmentsPlugin,
    FocusPlugin,
    ApiLimitationsPlugin,
    KeyboardShortcutsPlugin,
    TextApiPlugin,
    ClipboardPlugin,
    UndoRedoPlugin,
    StylesPlugin,
    OnChangePlugin,
    InlineToolbarPlugin,
    BlockToolbarPlugin,
    ForceSelectionOnBlurPlugin,
    CustomInputHandlingPlugin,
    ForceSingleUnstyledBlockPlugin,
    CommentsPlugin,
    SpellCheckPlugin,
    SimpleMultilineTextInputStatusPlugin,
  );

const useCompositionWithAi = () => {
  const { isTranslationsFeatureEnabled } = useContext(AiTranslationsContext);

  return usePluginComposition(
    useDefaultComposition(),
    LockEditorPlugin,
    useOptionalPlugin(isAnyAskAiFeatureEnabled, AiMenuPlugin),
    useOptionalPlugin(isAiMakeShorterEnabled, MakeShorterPlugin),
    useOptionalPlugin(isAiImproveContentEnabled, ImproveContentPlugin),
    useOptionalPlugin(isAiChangeToneEnabled, ChangeTonePlugin),
    useOptionalPlugin(isAiMatchWritingStyleEnabled, MatchWritingStyleOfItemPlugin),
    useOptionalPlugin(isAiCreateTitleEnabled, CreateTitlePlugin),
    useOptionalPlugin(isAiSummarizeEnabled, SummarizePlugin),
    EntityApiPlugin,
    useOptionalPlugin(isInlineAiEnabled, AiStylesPlugin),
    useOptionalPlugin(isInlineAiEnabled, InlineAiPlugin),
    useOptionalPlugin(isAiReviewEnabled, ReviewByGuidelinesPlugin),
    useOptionalPlugin(() => isTranslationsFeatureEnabled, TranslateContentPlugin),
  );
};

export type SimpleMultilineTextInputProps = EditorProps<typeof useCompositionWithAi>;

export const SimpleMultilineTextInput: React.FC<SimpleMultilineTextInputProps> = memo((props) => {
  const composition = useCompositionWithAi();

  return useEditor(composition, {
    ...props,
    dataUiInput: props.dataUiInput ?? DataUiInput.ItemTextElement,
    enterKeyBehavior: EnterKeyBehavior.AlwaysSoftNewLine,
    limitations: SimpleTextEditorLimitations,
    keyCommandMap: textKeyCommandMap,
  });
});

SimpleMultilineTextInput.displayName = 'SimpleMultilineTextInput';
