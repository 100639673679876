import { Button } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { not } from '@kontent-ai/utils';
import { FC, useRef, useState } from 'react';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { ShortcutSymbols } from '../../../../_shared/constants/shortcutSymbols.ts';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ContentItemsSearchArea } from '../../../contentInventory/content/components/ContentItemsSearchArea.tsx';
import { ContentItemScrollTable } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTable.tsx';
import { ContentItemScrollTableEmptyState } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTableEmptyState.tsx';
import { ContentItemScrollTableTitle } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTableTitle.tsx';
import { ContentItemFilter } from '../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { EnsureInventoryRelatedData } from '../../../contentInventory/content/features/ListingFilter/containers/EnsureInventoryRelatedData.tsx';
import { PreselectedFilter } from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { EnsureFullTextSearchStatus } from '../../../contentInventory/shared/containers/EnsureFullTextSearchStatus.tsx';
import { ModalDialog } from '../../../itemEditor/features/ContentItemEditing/components/modalDialog/ModalDialog.tsx';
import { useIsRefinedNavigationFeatureEnabled } from '../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { MultipleContentItemsSelectorScrollTableHead } from '../containers/MultipleContentItemsSelectorScrollTable/MultipleContentItemsSelectorScrollTableHead.tsx';
import { MultipleContentItemsSelectorScrollTableRow } from '../containers/MultipleContentItemsSelectorScrollTable/MultipleContentItemsSelectorScrollTableRow.tsx';

type Props = {
  readonly filterContentItems: () => void;
  readonly hideVariantSpecificInfo?: boolean;
  readonly isFilterInitialized: boolean;
  readonly isInitRetrieving: boolean;
  readonly itemIsAlreadyLinkedMsg?: string;
  readonly items: ReadonlyArray<IListingContentItem | null>;
  readonly listingItemsLoadingStatus: LoadingStatus;
  readonly loadContentItems: ThunkPromise;
  readonly onAssignSelectedEntries: () => void;
  readonly onClearFilter: () => void;
  readonly onCancel: () => void;
  readonly onItemDoubleClick?: (contentItemId: Uuid) => void;
  readonly preselectedFilter?: PreselectedFilter;
  readonly renderScrollTable: boolean;
  readonly selectedEntries: ReadonlyArray<Uuid>;
  readonly submitButtonText?: string;
  readonly titleBarText: string;
};

export const ModalMultipleContentItemsSelector: FC<Props> = (props) => {
  const contentPaneRef = useRef<HTMLDivElement>(null);
  const [isAssigning, setIsAssigning] = useState(false);

  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  const addRelatedEntries = (): void => {
    if (!isAssigning && props.selectedEntries.length) {
      props.onAssignSelectedEntries();
      setIsAssigning(true);
    }
  };

  const isAssignDisabled = isAssigning || !props.selectedEntries.length;
  const submitButtonText = props.submitButtonText || 'Insert';
  const disabledSubmitButtonTooltip = props.selectedEntries.length
    ? undefined
    : 'Select at least one content item';

  return (
    <ModalDialog
      buttons={
        <>
          <Button
            tooltipText="Cancel"
            tooltipPlacement="top"
            tooltipShortcuts={ShortcutSymbols.Escape}
            buttonStyle="secondary"
            type="button"
            onClick={props.onCancel}
            {...getDataUiActionAttribute(DataUiAction.Cancel)}
          >
            Cancel
          </Button>
          <Button
            tooltipText={isAssignDisabled ? disabledSubmitButtonTooltip : submitButtonText}
            tooltipPlacement="top"
            tooltipShortcuts={isAssignDisabled ? undefined : ShortcutSymbols.Enter}
            buttonStyle="primary"
            type="button"
            onClick={isAssignDisabled ? undefined : addRelatedEntries}
            disabled={isAssignDisabled}
            {...getDataUiActionAttribute(DataUiAction.Insert)}
          >
            {submitButtonText}
          </Button>
        </>
      }
      onCancel={props.onCancel}
      onSubmit={isAssignDisabled ? undefined : addRelatedEntries}
      titleBarText={props.titleBarText}
    >
      <div className="canvas__workspace canvas__workspace--in-modal">
        <EnsureInventoryRelatedData>
          {!isRefinedNavigationFeatureEnabled && (
            <ContentItemFilter
              clearFilter={props.onClearFilter}
              hideVariantSpecificInfo={props.hideVariantSpecificInfo}
              origin={ContentItemFilterOrigin.ModalMultipleContentItemsSelector}
            />
          )}
          <section className="canvas__content">
            <div className="canvas__notifications">
              <NotificationBar />
            </div>
            <div
              ref={contentPaneRef}
              className="canvas__content-pane
                           canvas__content-pane--no-bottom-offset
                           canvas__content-pane--without-vertical-scrollbar
                           canvas__content-pane--in-modal"
            >
              <div className="row">
                <div className="col-sm-24">
                  <EnsureFullTextSearchStatus>
                    <Stack spacing={Spacing.L}>
                      <FilterArea
                        onClearFilter={props.onClearFilter}
                        hideVariantSpecificInfo={props.hideVariantSpecificInfo}
                      />
                      {props.renderScrollTable ? (
                        <ContentItemScrollTable
                          ariaLabel="content item"
                          items={props.items}
                          onLoadContentItems={props.loadContentItems}
                          onItemDoubleClick={props.onItemDoubleClick}
                          parentContainerRef={contentPaneRef}
                          renderEmptyState={() => <ContentItemScrollTableEmptyState isInDialog />}
                          renderRowItem={(params) => (
                            <MultipleContentItemsSelectorScrollTableRow
                              hideVariantSpecificInfo={props.hideVariantSpecificInfo}
                              index={params.index}
                              item={params.item}
                              itemIsAlreadyLinkedMsg={props.itemIsAlreadyLinkedMsg}
                              key={params.item.item.id}
                              onItemDoubleClick={params.onItemDoubleClick}
                            />
                          )}
                          renderTableHead={() => (
                            <MultipleContentItemsSelectorScrollTableHead
                              hideVariantSpecificInfo={props.hideVariantSpecificInfo}
                            />
                          )}
                          renderTableTitle={() => <ContentItemScrollTableTitle />}
                          withColumnSettings={!props.hideVariantSpecificInfo}
                        />
                      ) : (
                        <Loader />
                      )}
                    </Stack>
                  </EnsureFullTextSearchStatus>
                </div>
              </div>
            </div>
          </section>
        </EnsureInventoryRelatedData>
      </div>
    </ModalDialog>
  );
};

const FilterArea = ({
  hideVariantSpecificInfo,
  onClearFilter,
}: {
  readonly hideVariantSpecificInfo?: boolean;
  readonly onClearFilter: () => void;
}) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  return (
    <Stack spacing={Spacing.None}>
      <ContentItemsSearchArea
        isFullTextSearchForceDisabled={hideVariantSpecificInfo}
        isInDialog
        isFilterActive={isFilterVisible}
        onToggleFilter={() => setIsFilterVisible(not)}
      />
      {isRefinedNavigationFeatureEnabled && isFilterVisible && (
        <ContentItemFilter
          clearFilter={onClearFilter}
          hideVariantSpecificInfo={hideVariantSpecificInfo}
          origin={ContentItemFilterOrigin.ModalMultipleContentItemsSelector}
        />
      )}
    </Stack>
  );
};
