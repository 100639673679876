export enum AiMessageErrorCode {
  ActionNotSupported = 'action-not-supported',
  AiTimeout = 'ai-timeout',
  Custom = 'custom',
  EmptyTaxonomies = 'empty-taxonomies',
  EmptyItemVariants = 'empty-item-variants',
  HarmfulContent = 'harmful-content',
  IncompleteContent = 'incomplete-content',
  InputHasTooFewCharacters = 'input-has-too-few-characters',
  InputHasTooFewTokens = 'input-has-too-few-tokens',
  InputHasTooManyCharacters = 'input-has-too-many-characters',
  InputHasTooManyTokens = 'input-has-too-many-tokens',
  InvalidImageFormat = 'invalid-image-format',
  InvalidImageSize = 'invalid-image-size',
  InvalidInput = 'invalid-input',
  MissingActionImplementation = 'missing-action-implementation',
  NoResult = 'no-result',
  TaxonomyWithoutNode = 'taxonomy-without-node',
  TaxonomyNotFound = 'taxonomy-not-fount',
  Unable = 'unable',
  UnableWithGeneratedReason = 'unable-with-generated-reason',
  UnexpectedResult = 'unexpected-result',
  UnknownIssue = 'unknown-issue',
  VariantNotFound = 'item-variant-not-fount',
  VariantWithoutAnySupportedElements = 'variant-without-elements',
}
