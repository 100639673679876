import { notNullNorUndefined } from '@kontent-ai/utils';
import { getProjectInfo } from '../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import {
  Capability,
  ICapability,
  getUserCapability,
} from '../../../_shared/utils/permissions/capability.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import { EnvironmentSettingsMenuStructureFeatureAvailabilityItem } from '../hooks/useGetEnvironmentSettingsMenuFeatureAvailability.ts';
import { isEnvironmentGeneralSettingsTabVisibleSelector } from '../selectors/environmentSettingsMenuCustomVisibilitiesSelectors.ts';
import {
  EnvironmentSettingsMenuStructureGroupItem,
  EnvironmentSettingsMenuStructureGroupItemWithCapability,
  environmentSettingsMenuStructure,
} from './environmentSettingsMenuStructure.tsx';

const isItemWithCapabilityVisible = (
  item: EnvironmentSettingsMenuStructureGroupItemWithCapability,
  capability: ICapability,
): boolean => capability.canOneOf([item.requiredCapability]);

export const isItemVisible = (
  item: EnvironmentSettingsMenuStructureGroupItem,
  capability: ICapability,
  environmentSettingsMenuFeatureAvailability: ReadonlyArray<EnvironmentSettingsMenuStructureFeatureAvailabilityItem>,
): boolean =>
  (!isItemWithCapabilities(item) || isItemWithCapabilityVisible(item, capability)) &&
  (environmentSettingsMenuFeatureAvailability.find((i) => i.routePath === item.routePath)
    ?.isFeatureEnabled ??
    true);

export const getFilteredOutMenuStructure = (
  currentEnvironment: IUserProjectInfo,
  menuStructure: ReadonlyArray<EnvironmentSettingsMenuStructureGroupItem>,
  environmentSettingsMenuFeatureAvailability: ReadonlyArray<EnvironmentSettingsMenuStructureFeatureAvailabilityItem>,
): ReadonlyArray<EnvironmentSettingsMenuStructureGroupItem> => {
  const environmentCapability = getUserCapability(currentEnvironment);

  return menuStructure.filter((item) =>
    isItemVisible(item, environmentCapability, environmentSettingsMenuFeatureAvailability),
  );
};

const isItemWithCapabilities = (
  item: EnvironmentSettingsMenuStructureGroupItem,
): item is EnvironmentSettingsMenuStructureGroupItemWithCapability => 'requiredCapability' in item;

export const environmentSettingsRequiredCapabilities: () => ReadonlyArray<Capability> = () =>
  environmentSettingsMenuStructure
    .filter(isItemWithCapabilities)
    .map((item) => item.requiredCapability)
    .filter(notNullNorUndefined);

export const canAccessEnvironmentSettings = (state: IStore, environmentId: Uuid): boolean => {
  const projectInfo = getProjectInfo(state, environmentId);
  return (
    getUserCapability(projectInfo).canOneOf(environmentSettingsRequiredCapabilities()) ||
    isEnvironmentGeneralSettingsTabVisibleSelector(state, projectInfo.projectId)
  );
};
