import { Collection } from '@kontent-ai/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../_shared/constants/trackedEvent.ts';
import { ModalContentItemSelectorLayout } from '../../../../_shared/features/ContentItemModalLayout/ModalContentItemSelectorLayout.tsx';
import { useDataSelector } from '../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getItemListForScrollTable } from '../../../../_shared/utils/getItemListForScrollTable.ts';
import { isItemsInitialLoadFinished } from '../../../../_shared/utils/isItemsInitialLoadFinished.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  clearContentItemListingFilter,
  filterContentItemsInItemSelector,
  loadListingContentItemsForInventory,
} from '../../../contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { ContentItemScrollTable } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTable.tsx';
import { ContentItemScrollTableEmptyState } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTableEmptyState.tsx';
import { ContentItemScrollTableTitle } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTableTitle.tsx';
import { getFindRightContentFilterEventData } from '../../../contentInventory/content/features/ContentItemInventory/utils/getFindRightContentTrackingPayloads.ts';
import {
  contentItemFilterLeft,
  contentItemFilterSetUp,
} from '../../../contentInventory/content/features/ListingFilter/actions/listingFilterActions.ts';
import {
  ContentItemFilter,
  isContentItemFilterInitialized,
} from '../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { useFilterChanged } from '../../../contentInventory/content/features/ListingFilter/hooks/useFilterChanged.ts';
import {
  PreselectedFilter,
  useSetUpContentItemFilter,
} from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { IListingFilter } from '../../../contentInventory/content/models/filter/IListingFilter.ts';
import { getNumberOfItemsInViewport } from '../../../contentInventory/content/reducers/listingUi/selectors/getNumberOfItemsInViewport.ts';
import { useSpacesIds } from '../../../environmentSettings/spaces/hooks/useSpaceIds.ts';
import {
  modalContentItemSelectorClosed,
  modalContentItemSelectorOpened,
} from '../actions/modalContentItemSelectorActions.ts';
import { ContentItemSelectorScrollTableHead } from './ContentItemSelectorScrollTable/ContentItemSelectorScrollTableHead.tsx';
import { ContentItemSelectorScrollTableRow } from './ContentItemSelectorScrollTable/ContentItemSelectorScrollTableRow.tsx';

type Props = {
  readonly allowedContentTypeIds?: ReadonlyArray<Uuid>;
  readonly collectionId?: Uuid | undefined;
  readonly forceContentTypesTooltipText?: string;
  readonly forcedContentTypeIds?: ReadonlySet<Uuid>;
  readonly getItemDisabledTooltipMessage?: (item: IListingContentItem) => string | undefined;
  readonly onClose: () => void;
  readonly onSelect: (contentItemId: Uuid) => void;
  readonly titleBarText: string;
};

const getItemsForScrollTable = (state: IStore): ReadonlyArray<IListingContentItem | null> => {
  const contentItemListingScrollTableState =
    state.contentApp.listingUi.contentItemListingScrollTableState;
  const listingContentItems = state.data.listingContentItems;

  return getItemListForScrollTable(
    listingContentItems,
    getNumberOfItemsInViewport(contentItemListingScrollTableState),
  );
};

export const ModalContentItemSelector = ({
  allowedContentTypeIds,
  collectionId,
  forceContentTypesTooltipText,
  forcedContentTypeIds,
  getItemDisabledTooltipMessage,
  onClose,
  onSelect,
  titleBarText,
}: Props) => {
  const dispatch = useDispatch();
  const isInitRetrieving = useSelector(
    (state) => !isItemsInitialLoadFinished(state.data.listingContentItems),
  );
  const scrollTableItems = useSelector(getItemsForScrollTable);
  const renderScrollTable = useSelector(
    (s) =>
      isItemsInitialLoadFinished(s.data.listingContentItems) &&
      isContentItemFilterInitialized(
        ContentItemFilterOrigin.ModalContentItemSelector,
        s.contentApp.listingUi.filterStatus,
      ),
  );
  const listingItemsLoadingStatus = useSelector((s) => s.data.listingContentItems.loadingStatus);
  const spaceIds = useSpacesIds(collectionId);

  const onCancel = () => {
    dispatch(modalContentItemSelectorClosed());
    onClose();
    dispatch(
      trackUserEvent(TrackedEvent.FindRightContent, {
        name: FindRightContentTrackedEvent.ModalSelectorCancelled,
      }),
    );
  };

  useEffect(() => {
    dispatch(modalContentItemSelectorOpened());
  }, []);

  const onSelectContentItem = (contentItemId: Uuid) => {
    dispatch(modalContentItemSelectorClosed());
    onSelect(contentItemId);
    dispatch(
      trackUserEvent(TrackedEvent.FindRightContent, {
        name: FindRightContentTrackedEvent.InsertedItemsFromModalSelector,
        count: 1,
      }),
    );
  };

  const preselectedFilter: PreselectedFilter = useMemo(
    () => ({
      selectedCollectionsNodes: collectionId ? new Set([collectionId]) : undefined,
      selectedContentTypesNodes: new Set(allowedContentTypeIds),
      selectedSpacesNodes: new Set(spaceIds),
    }),
    [allowedContentTypeIds, collectionId, spaceIds],
  );

  const filterChanged = () => dispatch(filterContentItemsInItemSelector());

  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  const workflows = useDataSelector((data) => data.workflows.byId);
  const filterSetUp = useCallback(
    (listingFilter: IListingFilter) => {
      dispatch(
        contentItemFilterSetUp(listingFilter, ContentItemFilterOrigin.ModalContentItemSelector),
      );
      dispatch(
        trackUserEvent(TrackedEvent.FindRightContent, {
          name: FindRightContentTrackedEvent.OpenedContentItemInventoryPage,
          ...getFindRightContentFilterEventData(
            listingFilter,
            ContentItemFilterOrigin.ModalContentItemSelector,
            Collection.getValues(workflows),
          ),
        }),
      );
    },
    [workflows],
  );
  const filterLeft = useCallback(
    () => dispatch(contentItemFilterLeft(ContentItemFilterOrigin.ModalContentItemSelector)),
    [],
  );

  useSetUpContentItemFilter(
    ContentItemFilterOrigin.ModalContentItemSelector,
    filterSetUp,
    filterLeft,
    preselectedFilter,
    forcedContentTypeIds,
  );

  useFilterChanged(filterChanged, filter, listingItemsLoadingStatus);

  return (
    <ModalContentItemSelectorLayout
      titleBarText={titleBarText}
      isInitRetrieving={isInitRetrieving}
      onCancel={onCancel}
      renderItemFilter={() => (
        <ContentItemFilter
          clearFilter={() => dispatch(clearContentItemListingFilter(forcedContentTypeIds))}
          forceContentTypesTooltipText={forceContentTypesTooltipText}
          forcedContentTypeIds={forcedContentTypeIds}
          origin={ContentItemFilterOrigin.ModalContentItemSelector}
        />
      )}
      renderScrollTable={(ref) => {
        return renderScrollTable ? (
          <ContentItemScrollTable
            ariaLabel="content item"
            items={scrollTableItems}
            onItemClick={onSelectContentItem}
            onItemDoubleClick={onSelectContentItem}
            onLoadContentItems={loadListingContentItemsForInventory(true)}
            parentContainerRef={ref}
            renderEmptyState={() => (
              <ContentItemScrollTableEmptyState
                forcedContentTypeIds={forcedContentTypeIds}
                isInDialog
              />
            )}
            renderRowItem={(params) => {
              const itemDisabledTooltipMessage = getItemDisabledTooltipMessage?.(params.item);
              return (
                <ContentItemSelectorScrollTableRow
                  disabledClickMessage={itemDisabledTooltipMessage}
                  key={params.item.item.id + params.index}
                  index={params.index}
                  item={params.item}
                  isDisabled={!!itemDisabledTooltipMessage}
                  onItemClick={itemDisabledTooltipMessage ? undefined : params.onItemClick}
                  onItemDoubleClick={
                    itemDisabledTooltipMessage ? undefined : params.onItemDoubleClick
                  }
                />
              );
            }}
            renderTableHead={() => (
              <ContentItemSelectorScrollTableHead
                onItemOrderChanged={() => dispatch(loadListingContentItemsForInventory())}
              />
            )}
            renderTableTitle={() => <ContentItemScrollTableTitle />}
            withColumnSettings
          />
        ) : (
          <Loader />
        );
      }}
    />
  );
};
