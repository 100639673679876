import { AiMessageErrorCode } from '../../../../repositories/serverModels/ai/AiMessageErrorCode.ts';

export enum AiErrorCode {
  ActionNotSupported = 'ActionNotSupported',
  General = 'General',
  InactivityTimeout = 'InactivityTimeout',
  InputContentFilter = 'InputContentFilter',
  InputTooLong = 'InputTooLong',
  InputTooShort = 'InputTooShort',
  InvalidData = 'InvalidData',
  NoResult = 'NoResult',
  OutputIncomplete = 'OutputIncomplete',
  ResponseContentFilter = 'ResponseContentFilter',
  Unable = 'Unable',
  UnableWithGeneratedReason = 'UnableWithGeneratedReason',
}

export const messageErrorCodeToErrorCodeMap: ReadonlyRecord<AiMessageErrorCode, AiErrorCode> = {
  [AiMessageErrorCode.ActionNotSupported]: AiErrorCode.ActionNotSupported,
  [AiMessageErrorCode.AiTimeout]: AiErrorCode.General,
  [AiMessageErrorCode.Custom]: AiErrorCode.UnableWithGeneratedReason,
  [AiMessageErrorCode.HarmfulContent]: AiErrorCode.InputContentFilter,
  [AiMessageErrorCode.IncompleteContent]: AiErrorCode.OutputIncomplete,
  [AiMessageErrorCode.InputHasTooFewCharacters]: AiErrorCode.InputTooShort,
  [AiMessageErrorCode.InputHasTooFewTokens]: AiErrorCode.InputTooShort,
  [AiMessageErrorCode.InputHasTooManyCharacters]: AiErrorCode.InputTooLong,
  [AiMessageErrorCode.InputHasTooManyTokens]: AiErrorCode.InputTooLong,
  [AiMessageErrorCode.InvalidInput]: AiErrorCode.General,
  [AiMessageErrorCode.MissingActionImplementation]: AiErrorCode.ActionNotSupported,
  [AiMessageErrorCode.NoResult]: AiErrorCode.NoResult,
  [AiMessageErrorCode.UnableWithGeneratedReason]: AiErrorCode.UnableWithGeneratedReason,
  [AiMessageErrorCode.Unable]: AiErrorCode.Unable,
  [AiMessageErrorCode.UnexpectedResult]: AiErrorCode.General,
  [AiMessageErrorCode.UnknownIssue]: AiErrorCode.General,

  // item variant errors
  [AiMessageErrorCode.EmptyItemVariants]: AiErrorCode.General,
  [AiMessageErrorCode.VariantNotFound]: AiErrorCode.InvalidData,
  [AiMessageErrorCode.VariantWithoutAnySupportedElements]: AiErrorCode.InvalidData,

  // image errors
  [AiMessageErrorCode.InvalidImageFormat]: AiErrorCode.InvalidData,
  [AiMessageErrorCode.InvalidImageSize]: AiErrorCode.InvalidData,

  // taxonomy errors
  [AiMessageErrorCode.EmptyTaxonomies]: AiErrorCode.General,
  [AiMessageErrorCode.TaxonomyNotFound]: AiErrorCode.InvalidData,
  [AiMessageErrorCode.TaxonomyWithoutNode]: AiErrorCode.InvalidData,
};

type SystemErrorCode = Exclude<AiErrorCode, AiErrorCode.UnableWithGeneratedReason>;

export type AiErrorCodeMap = ReadonlyRecord<SystemErrorCode, string>;

export type AiError =
  | {
      readonly code: AiErrorCode.UnableWithGeneratedReason;
      readonly message: string;
    }
  | {
      readonly code: SystemErrorCode;
      readonly message?: never;
    };

export const getAiErrorMessage = (
  error: AiError,
  messageMap: ReadonlyRecord<SystemErrorCode, string>,
) =>
  error.code === AiErrorCode.UnableWithGeneratedReason ? error.message : messageMap[error.code];
