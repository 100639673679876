import { px } from '@kontent-ai/component-library/tokens';
import { memoize } from '@kontent-ai/memoization';
import { animated, useTransition } from '@react-spring/web';
import Immutable from 'immutable';
import React, { useMemo, ComponentProps } from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getViewableContentGroups } from '../../../../../../_shared/utils/contentItemUtils.ts';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import { ICommentThreadItem } from '../../../../models/comments/CommentThreadItem.ts';
import { IInlineCommentThread } from '../../../../models/comments/CommentThreads.ts';
import { getEditedContentItemType } from '../../../../selectors/getEditedContentItemType.ts';
import {
  getSelectedContentGroupIdFromStateOrFirst,
  getTypeElement,
} from '../../../../stores/utils/contentItemElementsUtils.ts';
import {
  CommentThreadWithLocation,
  addNavigationData,
  getDisplayedInlineCommentThreads,
  isThreadSaved,
  pickCommentsWithNavigationData,
} from '../../../../utils/commentUtils.ts';
import { getItemElementCommentManager } from '../../../../utils/getItemElementCommentManager.ts';
import { InlineCommentPane as InlineCommentPaneComponent } from '../../components/comments/InlineCommentPane.tsx';
import {
  ContentItemPaneCommentListWidth,
  FullSizedEditorCommentsListWidth,
  FullSizedEditorCommentsPaneMargin,
} from '../../constants/uiConstants.ts';
import {
  getOrderedInlineComments,
  selectDisplayedInlineCommentThreads,
} from '../../selectors/inlineCommentSelectors.ts';
import { CreateContentGroupTabsId } from '../../utils/contentGroupTabsId.ts';
import { CommentThreadsOnRemovedContentOnboarding } from './CommentThreadsOnRemovedContentOnboarding.tsx';

const createGetCommentThreadPosition =
  (loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>) =>
  (commentThread: IInlineCommentThread): number | null => {
    const element = getTypeElement(loadedContentItemTypes, commentThread.elementId);
    if (!element) {
      return Number.NaN;
    }

    const commentManager = getItemElementCommentManager(element.type);
    const offset = commentManager.getCommentThreadOffset(commentThread);

    return offset;
  };

const isCommentThreadActive = memoize.weak(
  (thread: CommentThreadWithLocation, focusedCommentThreadId: Uuid | null) => {
    const commentThread = thread.commentThread;

    return (
      !isThreadSaved(commentThread) ||
      commentThread.id === focusedCommentThreadId ||
      commentThread.isInUndoResolvedState ||
      commentThread.isReplying ||
      commentThread.isSubmitting ||
      commentThread.threadItems.some(
        (item: ICommentThreadItem) => item.isEditing || item.isSubmitting,
      )
    );
  },
);

const getActiveCommentThreads = memoize.weak(
  <T extends CommentThreadWithLocation>(
    threads: ReadonlyArray<T>,
    focusedCommentThreadId: Uuid | null,
  ): ReadonlyArray<T> =>
    threads.filter((thread) => isCommentThreadActive(thread, focusedCommentThreadId)),
);

type Props = Pick<
  ComponentProps<typeof InlineCommentPaneComponent>,
  'scrollContainerRef' | 'threadListRef'
> & {
  readonly showsOnlyActiveComments?: boolean;
  readonly hasLeftMargin?: boolean;
};

export const InlineCommentPane: React.FC<Props> = (props) => {
  const loadedContentItemTypes = useSelector((state) => state.contentApp.loadedContentItemTypes);
  const editedContentItemType = useSelector(getEditedContentItemType);

  const selectedContentGroupId = useSelector((state) => {
    const contentItemId = state.contentApp.editedContentItem?.id;
    if (!contentItemId || !editedContentItemType) {
      return null;
    }

    const contentTypeGroups = editedContentItemType.contentGroups;
    const contentGroupTabsId = CreateContentGroupTabsId.forContentItem(contentItemId);
    return getSelectedContentGroupIdFromStateOrFirst(contentGroupTabsId, contentTypeGroups, state);
  });

  const focusedCommentThreadId = useSelector(
    (state) => state.contentApp.editedContentItemVariantComments.focusedCommentThreadId,
  );

  const displayedContentGroupInlineCommentThreads = useSelector((state) => {
    if (!editedContentItemType) {
      return [];
    }

    // We only include comment threads from viewable content groups in navigation
    // to make it consistent with numbers on the badges on content group tabs
    // and to avoid ending up in a dead-end of landing on a non-viewable comment while navigating
    const viewableContentGroups = getViewableContentGroups(
      editedContentItemType,
      state.contentApp.editorUi,
    );
    const viewableCommentThreads = editedContentItemType.contentGroups.length
      ? aggregateCommentThreads(
          viewableContentGroups.map((contentGroup) =>
            getOrderedInlineComments(state, contentGroup.id),
          ),
        )
      : getOrderedInlineComments(state);

    // We add navigation data to all comments and then pick just the ones for the current group
    // to make sure the comment navigation loops through all content groups in the item, not just one
    const allInlineCommentThreads = addNavigationData(
      getDisplayedInlineCommentThreads(viewableCommentThreads),
    );
    const contentGroupInlineCommentThreads = getDisplayedInlineCommentThreads(
      getOrderedInlineComments(state, selectedContentGroupId),
    );
    const commentThreadsWithNavigationData = pickCommentsWithNavigationData(
      allInlineCommentThreads,
      contentGroupInlineCommentThreads,
    );

    return props.showsOnlyActiveComments
      ? getActiveCommentThreads(commentThreadsWithNavigationData, focusedCommentThreadId)
      : commentThreadsWithNavigationData;
  });

  const allowKeyboardNavigation = displayedContentGroupInlineCommentThreads.some(
    (thread) => thread.navigationData && thread.commentThread.id === focusedCommentThreadId,
  );

  const getCommentThreadPosition = useMemo(
    () => createGetCommentThreadPosition(loadedContentItemTypes),
    [loadedContentItemTypes],
  );

  const isVisible = useSelector((state) => {
    const itemHasInlineCommentThreads = !!selectDisplayedInlineCommentThreads(state).length;
    return (
      state.contentApp.isCommentsPaneVisible &&
      (itemHasInlineCommentThreads || !!props.showsOnlyActiveComments)
    );
  });

  const transitions = useTransition(isVisible, {
    initial: {
      opacity: 1,
      maxWidth: px(
        props.hasLeftMargin ? FullSizedEditorCommentsListWidth : ContentItemPaneCommentListWidth,
      ),
    },
    from: {
      opacity: 0,
      maxWidth: '0px',
    },
    enter: {
      opacity: 1,
      maxWidth: px(
        props.hasLeftMargin ? FullSizedEditorCommentsListWidth : ContentItemPaneCommentListWidth,
      ),
    },
    leave: {
      opacity: 0,
      maxWidth: '0px',
    },
  });

  return (
    <>
      {transitions((style, item, transitionState) => {
        return (
          item && (
            <animated.div
              css={`margin-left: ${px(props.hasLeftMargin ? FullSizedEditorCommentsPaneMargin : 0)}`}
              className="content-item-pane__comment-pane comment-pane"
              key={transitionState.ctrl.id}
              style={style}
            >
              <InlineCommentPaneComponent
                {...props}
                allowKeyboardNavigation={allowKeyboardNavigation}
                threads={displayedContentGroupInlineCommentThreads}
                focusedCommentThreadId={focusedCommentThreadId}
                getCommentThreadPosition={getCommentThreadPosition}
                key={selectedContentGroupId}
              />
            </animated.div>
          )
        );
      })}
      <CommentThreadsOnRemovedContentOnboarding />
    </>
  );
};

const aggregateCommentThreads = memoize.maxNWithTransformedArgs(
  (
    commentThreads: ReadonlyArray<ReadonlyArray<CommentThreadWithLocation>>,
  ): ReadonlyArray<CommentThreadWithLocation> => commentThreads.flat(),
  ([commentThreads]) => commentThreads,
  1,
);
