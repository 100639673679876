import { assert } from '@kontent-ai/utils';
import { History } from 'history';
import {
  Dispatch,
  GetState,
  ThunkFunction,
  ThunkPromise,
} from '../../../../../../@types/Dispatcher.type.ts';
import { IContentItemWithVariantsServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { markEditedItemAsTemporary } from '../../../ContentItemEditing/actions/contentItemEditingActions.ts';
import { CollectionSection } from '../../constants/collectionSection.ts';
import { ContentTypeSection } from '../../constants/contentTypeSection.ts';
import { getNewContentItemForm } from '../../selectors/newContentItemDialog.ts';
import { ICreateNewContentItemAction } from './createNewContentItem.ts';

interface IDeps {
  readonly createNewContentItem: ICreateNewContentItemAction;
  readonly redirectToItem: (history: History, contentItemId: Uuid, isPreviewOpen: boolean) => void;
  readonly trackItemCreated: (
    contentTypeSection: ContentTypeSection,
    collectionSection: CollectionSection,
  ) => ThunkFunction;
}

interface IDepsWithSelectedLanguage {
  readonly createNewContentItem: ICreateNewContentItemAction;
  readonly redirectToItem: (
    history: History,
    contentItemId: Uuid,
    languageId: string,
    isPreviewOpen: boolean,
  ) => void;
  readonly trackItemCreated: (
    contentTypeSection: ContentTypeSection,
    collectionSection: CollectionSection,
  ) => ThunkFunction;
}

export const createContentItemFromContentTypeCreator =
  (deps: IDeps) =>
  (history: History, isPreviewOpen: boolean): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { collectionSection, contentTypeSection } = getNewContentItemForm(getState());
    const createdItem = await dispatch(createNewItem(deps.createNewContentItem));

    dispatch(deps.trackItemCreated(contentTypeSection, collectionSection));
    deps.redirectToItem(history, createdItem.item.id, isPreviewOpen);
  };

export const createContentItemFromContentTypeWithSelectedLanguageCreator =
  (deps: IDepsWithSelectedLanguage) =>
  (history: History, isPreviewOpen: boolean): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { collectionSection, contentTypeSection, languageId } = getNewContentItemForm(getState());

    const createdItem = await dispatch(createNewItem(deps.createNewContentItem));

    dispatch(deps.trackItemCreated(contentTypeSection, collectionSection));
    deps.redirectToItem(history, createdItem.item.id, languageId, isPreviewOpen);
  };

const createNewItem =
  (
    createNewContentItem: ICreateNewContentItemAction,
  ): ThunkPromise<IContentItemWithVariantsServerModel> =>
  async (dispatch, getState): Promise<IContentItemWithVariantsServerModel> => {
    const { collectionId, contentTypeId, languageId } = getNewContentItemForm(getState());

    assert(contentTypeId, () => 'Can’t create item without a content type selected.');
    assert(collectionId, () => 'Can’t create item without a collection selected.');

    const createdItem = await dispatch(
      createNewContentItem({
        typeId: contentTypeId,
        collectionId,
      }),
    );

    dispatch(
      markEditedItemAsTemporary({
        itemId: createdItem.item.id,
        variantId: languageId,
        parentItem: null,
      }),
    );

    return createdItem;
  };
