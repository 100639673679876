import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Toggle } from '@kontent-ai/component-library/Toggle';
import { noOperation } from '@kontent-ai/utils';
import { useContext } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EditingActionsButton } from '../../components/editingActions/actions/EditingActionsButton.tsx';
import { ItemEditingNewDesignContext } from '../../context/ItemEditingNewDesignContext.tsx';
import { useNavigationTree } from '../../features/contentItemPreview/context/NavigationTreeContext.tsx';

export const TreeToggle = () => {
  const { isTreeOpened, setIsTreeOpened } = useNavigationTree();
  const isNewDesign = useContext(ItemEditingNewDesignContext);

  return isNewDesign ? (
    <QuinaryButton
      activated={isTreeOpened}
      aria-label="Toggle tree"
      aria-pressed={isTreeOpened}
      onClick={() => setIsTreeOpened(!isTreeOpened)}
      tooltipPlacement="bottom-start"
      tooltipText="Tree"
      {...getDataUiElementAttribute(DataUiElement.ContentItemTreeToggle)}
    >
      <QuinaryButton.Icon icon={Icons.TreeStructure} />
    </QuinaryButton>
  ) : (
    <Box display="grid" alignItems="center" css={`grid-template-areas: "stack";`}>
      <Box css="grid-area: stack;" visibility="hidden" aria-hidden="true">
        <EditingActionsButton
          buttonStyle="tertiary"
          disableTabulator
          iconName="Ellipsis"
          isActive={false}
          onClick={noOperation}
          text="x"
        />
      </Box>
      <Box css="grid-area: stack;">
        <Toggle
          labelText="Tree"
          status={isTreeOpened ? 'on' : 'off'}
          tooltipPlacement="bottom-start"
          onToggleOn={() => setIsTreeOpened(true)}
          onToggleOff={() => setIsTreeOpened(false)}
          {...getDataUiElementAttribute(DataUiElement.ContentItemTreeToggle)}
        />
      </Box>
    </Box>
  );
};
