import { Box } from '@kontent-ai/component-library/Box';
import classNames from 'classnames';
import { PropsWithChildren, forwardRef, useRef } from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import {
  DataUiAppName,
  DataUiElement,
  getDataUiAppNameAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentItemEditor } from '../containers/ContentItemEditor.tsx';
import { ContentItemNotifications } from '../containers/ContentItemNotifications.tsx';
import { WaitForEditedItemInitialized } from '../containers/WaitForEditedItemInitialized.tsx';
import { EditingActionsBar } from '../containers/editingActions/EditingActionsBar.tsx';
import { ContentItemSidebar } from '../containers/sidebar/ContentItemSidebar.tsx';
import { ContentItemPreview } from '../features/contentItemPreview/containers/ContentItemPreview.tsx';
import { NavigationTree } from '../features/contentItemPreview/containers/NavigationTree.tsx';
import { ContentItemSidebarPlaceholder } from './sidebar/ContentItemSidebarPlaceholder.tsx';

type Props = Readonly<{
  isLivePreviewOpen: boolean;
  isTreeOpen: boolean;
}>;

export const ItemEditingLayout = ({ isLivePreviewOpen, isTreeOpen }: Props) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const contentItemCanvasRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="canvas"
      {...getDataUiAppNameAttribute(DataUiAppName.ContentItemEditing)}
      data-hj-suppress=""
    >
      <div className="canvas__workspace">
        <section className={classNames('canvas__content', 'content-item-editor')}>
          <WaitForEditedItemInitialized>
            <ContentItemNotifications />
          </WaitForEditedItemInitialized>
          <EditingActionsBar />
          <Box
            display="flex"
            css={`
              grid-area: editor;
              min-height: 0;
            `}
          >
            {isTreeOpen && (
              <Box width={300}>
                <NavigationTree />
              </Box>
            )}
            <WaitForEditedItemInitialized renderWhileWaiting={() => <Loader />}>
              {isLivePreviewOpen && (
                <ContentItemPreviewLayout ref={previewRef}>
                  <ContentItemPreview />
                </ContentItemPreviewLayout>
              )}
              <ContentItemEditorLayout isPreviewOpen={isLivePreviewOpen}>
                <ContentItemEditor
                  contentItemCanvasRef={contentItemCanvasRef}
                  isPreviewOpen={isLivePreviewOpen}
                />
              </ContentItemEditorLayout>
            </WaitForEditedItemInitialized>
          </Box>
          <WaitForEditedItemInitialized
            renderWhileWaiting={
              isLivePreviewOpen ? () => <ContentItemSidebarPlaceholder isDisabled /> : undefined
            }
          >
            <ContentItemSidebar
              hasPlaceholder
              outsideClickElementRefs={[contentItemCanvasRef, previewRef]}
            />
            <ContentItemSidebarPlaceholder />
          </WaitForEditedItemInitialized>
        </section>
      </div>
    </div>
  );
};

type ContentItemEditorLayoutProps = PropsWithChildren &
  Readonly<{
    isPreviewOpen: boolean;
  }>;

const ContentItemEditorLayout = ({ children, isPreviewOpen }: ContentItemEditorLayoutProps) => {
  return (
    <Box
      display="flex"
      flexBasis="0"
      flexGrow={1}
      flexShrink={1}
      justifyContent="center"
      maxWidth={isPreviewOpen ? 'max(30%, 400px)' : undefined}
    >
      {children}
    </Box>
  );
};

const ContentItemPreviewLayout = forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children }, ref) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        flexShrink={1}
        flexBasis={0}
        height="100%"
        ref={ref}
        {...getDataUiElementAttribute(DataUiElement.ContentItemPreview)}
      >
        {children}
      </Box>
    );
  },
);
