import { dismissContextualHelp } from '../../../../_shared/actions/thunkSharedActions.ts';
import { ContextualHelp } from '../../../../_shared/components/ContextualHelp/ContextualHelp.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getSampleProjectType } from '../../../../_shared/models/utils/quickstartUtils.ts';
import { ContextualHelpType } from '../../../../data/models/contextualHelp/ContextualHelpTypeEnum.ts';
import { SampleProjectType } from '../../../projects/constants/sampleProjectType.ts';

const bulletPoints = [
  () => (
    <>
      Combine <b>text, rich text, and asset</b> elements to create your basic content structure.
    </>
  ),
  () => (
    <>
      Add <b>guidelines</b> to help content creators produce the right content.
    </>
  ),
];

export const ContentTypeEditorContextualHelp = () => {
  const dispatch = useDispatch();
  const shouldShow = useSelector(
    (s) =>
      getSampleProjectType(s.sharedApp.projectProperties) === SampleProjectType.Kickstart &&
      s.sharedApp.userProperties.contextualHelpSettings.get(ContextualHelpType.ContentType, false),
  );

  return (
    <ContextualHelp
      bulletPoints={bulletPoints}
      title="You’re in a content type"
      shouldShow={shouldShow}
      onDismiss={() => dispatch(dismissContextualHelp(ContextualHelpType.ContentType))}
      callToAction={
        <>
          Add elements and <b>save</b> your type.
        </>
      }
    />
  );
};
