import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { noOperation } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React from 'react';
import {
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../../_shared/constants/routePaths.ts';
import { DefaultWorkflowId } from '../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getWorkflow, getWorkflowStep } from '../../../../_shared/selectors/workflowSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  buildPath,
  pickContentItemRoute,
} from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getWorkflowTooltip } from '../../../../_shared/utils/workflow/getWorkflowTooltip.ts';
import { hasProjectMultipleWorkflows } from '../../../../_shared/utils/workflow/hasProjectMultipleWorkflows.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { IAssignmentWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import { useLivePreviewPreferenceStorage } from '../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import {
  CategoryItemsListingModal as CategoryItemsListingModalComponent,
  ProjectOverviewItem,
} from '../components/CategoryItemsListingModal.tsx';
import { CategoryItemsListingModalView } from '../components/ListingViews/ListingView.tsx';
import { SelectedWorkflowStep } from '../components/ProjectOverviewPageContext.tsx';
import { ListingCategory } from '../models/ProjectOverviewListingCategory.ts';

interface Configuration {
  readonly itemIds: Immutable.Set<Uuid>;
  readonly title: string;
  readonly view: CategoryItemsListingModalView;
}

const emptyConfiguration: Configuration = {
  itemIds: Immutable.Set(),
  title: '',
  view: CategoryItemsListingModalView.Full,
};

const createGetWorkflowDescription: (
  workflows: ReadonlyMap<Uuid, Workflow>,
  hasMultipleWorkflows: boolean,
) => (step: IAssignmentWorkflowStep) => string | undefined = (workflows, hasMultipleWorkflows) => {
  const defaultWorkflow = workflows.get(DefaultWorkflowId);

  if (!hasMultipleWorkflows || !defaultWorkflow) {
    return noOperation;
  }

  return (step) => {
    const workflow = workflows.get(step.workflowId);
    if (!workflow) {
      return undefined;
    }

    return getWorkflowTooltip(workflow.name);
  };
};

function getCategoryConfiguration(
  category: ListingCategory,
  workflowStep: SelectedWorkflowStep | null,
  state: IStore,
): Configuration | null {
  switch (category) {
    case ListingCategory.Delayed:
      return {
        itemIds: state.projectOverviewApp.aggregations.delayedItemsIds,
        title: 'Delayed content',
        view: CategoryItemsListingModalView.Full,
      };
    case ListingCategory.OnTrack:
      return {
        itemIds: state.projectOverviewApp.aggregations.itemsOnTrackIds,
        title: 'Content on track',
        view: CategoryItemsListingModalView.Full,
      };
    case ListingCategory.ScheduledToPublish:
      return {
        itemIds: state.projectOverviewApp.aggregations.scheduledToPublishItemsIds,
        title: 'Content scheduled to publish',
        view: CategoryItemsListingModalView.ScheduledToPublishAt,
      };
    case ListingCategory.WithoutDueDate:
      return {
        itemIds: state.projectOverviewApp.aggregations.itemsWithoutDueDatesIds,
        title: 'Content without due date',
        view: CategoryItemsListingModalView.WorkflowStep,
      };
    case ListingCategory.ByWorkflowStep: {
      if (!workflowStep) {
        throw InvariantException(
          'CategoryItemsListingModal: Workflow step category displayed without selected Ids.',
        );
      }

      const workflow = getWorkflow(state, workflowStep.workflowId);
      const step = workflow ? getWorkflowStep(workflow, workflowStep.stepId) : null;
      if (!step) {
        return emptyConfiguration;
      }

      const itemIds = state.projectOverviewApp.aggregations.itemsByWorkflowByStep
        .get(workflowStep.workflowId)
        ?.get(workflowStep.stepId);
      if (!itemIds) {
        return emptyConfiguration;
      }

      const getWorkflowDescription = createGetWorkflowDescription(
        state.data.workflows.byId,
        hasProjectMultipleWorkflows(state.data.workflows.byId),
      );
      const workflowDescription = getWorkflowDescription({
        workflowId: workflowStep.workflowId,
        ...step,
      });

      const title = workflowDescription
        ? `Content in step: ${step.name} (from ${workflowDescription})`
        : `Content in ${step.name} workflow step`;

      return {
        itemIds: Immutable.Set(itemIds),
        title,
        view: CategoryItemsListingModalView.Full,
      };
    }
    case ListingCategory.None:
      return null;
  }
}

const compareByLastUpdatedDesc = (itemA: ProjectOverviewItem, itemB: ProjectOverviewItem): number =>
  (itemB.lastUpdatedAt ?? '').localeCompare(itemA.lastUpdatedAt ?? '');

const convertContentItems = memoize.weak(
  (
    contentItems: Immutable.Set<Uuid>,
    selectedLanguageId: Uuid,
    currentProjectId: Uuid,
    itemsById: Immutable.Map<Uuid, IListingContentItem>,
    contentTypesById: Immutable.Map<Uuid, IContentType>,
    isPreviewOpen: boolean,
  ) =>
    contentItems
      .toArray()
      .map((id: Uuid): ProjectOverviewItem | null => {
        const item = itemsById.get(id);
        if (!item) {
          return null;
        }
        const {
          item: { typeId, name },
          variant,
        } = item;
        const contentType = contentTypesById.get(typeId);
        if (!variant || !contentType) {
          return null;
        }
        const {
          assignment: { workflowStatus, due },
        } = variant;

        return {
          id,
          name,
          workflowStatus,
          contentType: contentType.name,
          lastUpdatedAt: variant.lastModified,
          scheduledToPublishAt: variant.assignment.scheduledToPublishAt,
          scheduledToUnpublishAt: variant.assignment.scheduledToUnpublishAt,
          publishingState: variant.publishingState,
          due,
          linkTo: buildPath<ContentItemRouteParams<UuidArray>>(
            pickContentItemRoute(isPreviewOpen),
            {
              app: ContentItemsAppRouteSegment.Content,
              variantId: selectedLanguageId,
              projectId: currentProjectId,
              spaceId: undefined,
              contentItemIds: [id],
            },
          ),
          isComplete: variant.isComplete,
        };
      })
      .filter((x) => !!x)
      .sort(compareByLastUpdatedDesc),
);

type Props = {
  readonly isOpen: boolean;
  readonly listingSelectedWorkflowStep: SelectedWorkflowStep | null;
  readonly onCancel: () => void;
  readonly viewedCategory: ListingCategory;
};

export const CategoryItemsListingModal: React.FC<Props> = ({
  isOpen,
  listingSelectedWorkflowStep,
  onCancel,
  viewedCategory,
}) => {
  const selectedLanguageId = useSelector(getSelectedLanguageIdOrThrow);
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const configuration = useSelector((state) =>
    getCategoryConfiguration(viewedCategory, listingSelectedWorkflowStep, state),
  );
  const contentItems = useSelector(
    (state) =>
      configuration &&
      convertContentItems(
        configuration.itemIds,
        selectedLanguageId,
        currentProjectId,
        state.data.listingContentItems.byId,
        state.data.contentTypes.byId,
        isLivePreviewPreferred,
      ),
  );

  if (!configuration || !contentItems) {
    return null;
  }

  return (
    <CategoryItemsListingModalComponent
      contentItems={contentItems}
      isOpen={isOpen}
      onCancel={onCancel}
      title={configuration.title}
      view={configuration.view}
    />
  );
};

CategoryItemsListingModal.displayName = 'CategoryItemsListingModal';
