import { Location } from 'history';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { LinkVisitedEventData } from '../../../../_shared/models/events/ContentItemEventData.type.ts';
import { parseQueryParams } from '../../../../_shared/utils/routing/queryParamsUtils.ts';
import { editItemVariant } from '../thunkSmartLinkActions.ts';
import { IEditItemVariantParameters } from './editItemVariant.ts';

type Params = {
  readonly projectId: Uuid;
  readonly itemId: Uuid;
  readonly variantId: Uuid;
  readonly location: Location;
  readonly isPreviewOpen: boolean;
};

export const initEditItemVariantSmartLink =
  (
    { projectId, itemId, variantId, location, isPreviewOpen }: Params,
    abortSignal: AbortSignal,
  ): ThunkPromise =>
  async (dispatch) => {
    const linkId = parseQueryParams<{ readonly linkId: string }>(location.search).linkId ?? '';
    const eventData: LinkVisitedEventData = {
      'journey-stage': 'opened',
      itemId,
      linkId,
      projectId,
      variantId,
    };

    dispatch(trackUserEvent(TrackedEvent.LinkVisited, eventData));

    const editVariantData: IEditItemVariantParameters = {
      projectId,
      variantId,
      path: [{ itemId }],
      isPreviewOpen,
    };
    await dispatch(editItemVariant(editVariantData, abortSignal));
  };
