import { Paper } from '@kontent-ai/component-library/Paper';
import {
  BorderRadius,
  Spacing,
  colorBackgroundLowEmphasis,
} from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { ContentItemFilterOrigin } from '../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISavedFilter } from '../../../../../../data/models/filters/ISavedFilter.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { ClearFilterButton } from '../../../../shared/components/filter/ClearFilterButton.tsx';
import { IListingFilter, isListingFilterEmpty } from '../../../models/filter/IListingFilter.ts';
import { ISavedFiltersUi } from '../../../reducers/listingUi/reducers/savedFiltersUi/ISavedFiltersUi.type.ts';
import { filterHeadlineIdentifier } from '../constants/listingFilterConstants.ts';
import { SavedFiltersListing } from '../containers/savedFilters/SavedFiltersListing.tsx';
import { useSelectedFilterId } from '../hooks/useSelectedFilterId.ts';
import { FilterActionsMenu } from './savedFilters/FilterActionsMenu.tsx';

type Props = {
  readonly filter: IListingFilter;
  readonly filters: ReadonlyArray<ISavedFilter>;
  readonly forcedContentTypeIds: ReadonlySet<Uuid> | undefined;
  readonly hideVariantSpecificInfo?: boolean;
  readonly isFilterBeingCreated: boolean;
  readonly onClearFilter: () => void;
  readonly onCreateLinkedFilter: () => void;
  readonly onSaveFilter: (name: string) => void;
  readonly origin: ContentItemFilterOrigin;
  readonly renderSelectors: () => JSX.Element;
  readonly savedFilters: ReadonlyMap<Uuid, ISavedFilter>;
  readonly savedFiltersUi: ISavedFiltersUi;
};

export const ContentItemFilter = (props: Props) => {
  const selectedFilterId = useSelectedFilterId(props.filter, props.savedFilters);
  const shouldShowClearFilterButton = !isListingFilterEmpty(
    props.filter,
    props.forcedContentTypeIds,
  );
  const canSaveCurrentFilter =
    !selectedFilterId && !isListingFilterEmpty(props.filter) && !props.isFilterBeingCreated;
  const isFilterInModal =
    props.origin === ContentItemFilterOrigin.ModalContentItemSelector ||
    props.origin === ContentItemFilterOrigin.ModalMultipleContentItemsSelector;

  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  if (!isRefinedNavigationFeatureEnabled) {
    return (
      <div
        aria-labelledby={filterHeadlineIdentifier}
        className={classNames('content-item-filter', 'canvas__sidebar', {
          'content-item-filter--in-modal': isFilterInModal,
        })}
      >
        <div className="content-item-filter__content">
          {!props.hideVariantSpecificInfo && (
            <SavedFiltersListing
              filters={props.filters}
              forcedContentTypeIds={props.forcedContentTypeIds}
              origin={props.origin}
              savedFiltersUi={props.savedFiltersUi}
              selectedFilterId={selectedFilterId}
            />
          )}
          <div className="facet" {...getDataUiElementAttribute(DataUiElement.Filter)}>
            <div className="facet__title">
              <div id={filterHeadlineIdentifier} className="facet__title-text">
                Refine results
              </div>
              {shouldShowClearFilterButton && (
                <div className="facet__title-actions">
                  <ClearFilterButton onClick={props.onClearFilter} />
                  {!props.hideVariantSpecificInfo && (
                    <FilterActionsMenu
                      canSaveCurrentFilter={canSaveCurrentFilter}
                      savedFiltersUi={props.savedFiltersUi}
                      onCreateLinkedFilter={props.onCreateLinkedFilter}
                      onSaveFilter={props.onSaveFilter}
                    />
                  )}
                </div>
              )}
            </div>
            {props.renderSelectors()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Paper
      borderRadius={BorderRadius.M}
      backgroundColor={colorBackgroundLowEmphasis}
      borderColor={colorBackgroundLowEmphasis}
      paddingBottom={Spacing.L}
    >
      {props.renderSelectors()}
    </Paper>
  );
};
