import { dismissContextualHelp } from '../../../../_shared/actions/thunkSharedActions.ts';
import { ContextualHelp } from '../../../../_shared/components/ContextualHelp/ContextualHelp.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getSampleProjectType } from '../../../../_shared/models/utils/quickstartUtils.ts';
import { ContextualHelpType } from '../../../../data/models/contextualHelp/ContextualHelpTypeEnum.ts';
import { SampleProjectType } from '../../../projects/constants/sampleProjectType.ts';

const bulletPoints = [
  () => 'They are templates for your content items that ensure their consistent structure.',
  () => 'The predefined structure allows you to process content on any device.',
];

export const ContentTypeListingContextualHelp = () => {
  const dispatch = useDispatch();
  const shouldShow = useSelector(
    (s) =>
      getSampleProjectType(s.sharedApp.projectProperties) === SampleProjectType.Kickstart &&
      s.sharedApp.userProperties.contextualHelpSettings.get(ContextualHelpType.ContentTypes, false),
  );

  return (
    <ContextualHelp
      shouldShow={shouldShow}
      bulletPoints={bulletPoints}
      title="These are your content types"
      onDismiss={() => dispatch(dismissContextualHelp(ContextualHelpType.ContentTypes))}
      callToAction={
        <>
          Edit an example type or <b>create a new</b> one.
        </>
      }
    />
  );
};
