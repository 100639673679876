import { InvariantException } from '@kontent-ai/errors';
import Immutable from 'immutable';
import {
  CompiledTypeElementType,
  ElementType,
} from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { CommentThreadItemType } from '../../../models/comments/CommentThreadItem.ts';
import {
  CommentThreadType,
  ICommentThread,
  IInlineCommentThread,
} from '../../../models/comments/CommentThreads.ts';
import {
  isThreadInline,
  isThreadResolved,
  isThreadResolvedWithoutUndo,
  isThreadSaved,
} from '../../../utils/commentUtils.ts';

export const isDisplayedInlineCommentThread = (
  thread: ICommentThread,
): thread is IInlineCommentThread => isThreadInline(thread) && !isThreadResolvedWithoutUndo(thread);

export const isUnresolvedSavedInlineCommentThread = (
  thread: ICommentThread,
): thread is IInlineCommentThread =>
  !isThreadResolved(thread) && isThreadInline(thread) && isThreadSaved(thread);

const typesWithCommentPossibility: ReadonlyArray<CompiledTypeElementType> = [
  ElementType.Number,
  ElementType.Taxonomy,
  ElementType.MultipleChoice,
  ElementType.DateTime,
  ElementType.Custom,
];

export const areCommentSupported = (elementType: CompiledTypeElementType): boolean => {
  return typesWithCommentPossibility.includes(elementType);
};

export const getNewCommentThreadItemType = (
  newCommentThreadItemTypeMapping: Immutable.Map<string, CommentThreadItemType>,
  commentThreadId: Uuid,
): CommentThreadItemType | undefined => newCommentThreadItemTypeMapping.get(commentThreadId);

export const getCommentThreadType = (elementType: CompiledTypeElementType): CommentThreadType => {
  switch (elementType) {
    case ElementType.Asset:
      return CommentThreadType.Asset;
    case ElementType.Number:
      return CommentThreadType.Number;
    case ElementType.Taxonomy:
      return CommentThreadType.Taxonomy;
    case ElementType.MultipleChoice:
      return CommentThreadType.MultipleChoice;
    case ElementType.DateTime:
      return CommentThreadType.DateTime;
    case ElementType.Custom:
      return CommentThreadType.CustomElement;
    default:
      throw InvariantException(
        `Content element of ${elementType} doesn’t support element comments.`,
      );
  }
};
