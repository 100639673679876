import { Box } from '@kontent-ai/component-library/Box';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Typography, gridUnit } from '@kontent-ai/component-library/tokens';
import { useIdWithPrefix } from '@kontent-ai/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { FullScreenModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { CollectionsMap } from '../../../../../data/models/collections/Collection.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { createPublishedListingContentItemRecordFromServerModel } from '../../../../../data/models/listingContentItems/PublishedListingContentItemRecord.ts';
import {
  ListingRequestOptionsModel,
  ListingServerResponseModel,
} from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { TimeInterval, timeIntervals } from '../../../constants/timeIntervals.ts';
import { MissionControlRepository } from '../../../repositories/MissionControlRepository.type.ts';
import { PublishedItemsServerModel } from '../../../repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { StyledDataTable } from '../../shared/components/StyledDataTable.tsx';
import { Widget } from '../../shared/components/Widget.tsx';
import { WidgetListingContentShowMoreButton } from '../../shared/components/WidgetListingContentShowMoreButton.tsx';
import { WidgetListingStateContent } from '../../shared/components/WidgetListingStateContent.tsx';
import { entityListWidgetDisplayCount } from '../../shared/constants/widgetConstants.ts';
import { useWidgetListingDataFetcher } from '../../shared/hooks/useWidgetListingDataFetcher.tsx';
import { PublishedItemsWidgetDetailContainer } from '../containers/PublishedItemsWidgetDetailContainer.tsx';
import { PublishedItemsCountRequestModel } from '../types/PublishedItemsCountRequestModel.type.ts';
import { PublishedItemsFilterModel } from '../types/PublishedItemsFilterModel.type.ts';
import { PublishedItemsListingRequestOptionsModel } from '../types/PublishedItemsListingRequestOptionsModel.type.ts';
import { getCollectionNameFromId } from '../utils/getCollectionNameFromId.ts';
import { getContentTypeNameFromId } from '../utils/getContentTypeNameFromId.ts';
import { PublishedItemsWidgetRow } from './PublishedItemsWidgetRow.tsx';
import { WidgetEmptyState } from './WidgetEmptyState.tsx';

type PublishedItemsWidgetProps = Readonly<{
  collectionIds: CollectionsMap;
  contentTypeByIds: Immutable.Map<Uuid, IContentType>;
  isPreviewOpen: boolean;
  itemsCountFetcher: MissionControlRepository['getPublishedItemsCount'];
  itemsFetcher: MissionControlRepository['getPublishedItems'];
  onOverviewItemClick?: () => void;
  onShowMoreClick?: () => void;
  onDetailItemClick?: () => void;
}>;

export const PublishedItemsWidget = ({
  collectionIds,
  contentTypeByIds,
  isPreviewOpen,
  itemsCountFetcher,
  itemsFetcher,
  onDetailItemClick,
  onOverviewItemClick,
  onShowMoreClick,
}: PublishedItemsWidgetProps) => {
  const widgetTitleId = useIdWithPrefix('published-items');
  const { pathname } = useLocation();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const openDetail = () => {
    onShowMoreClick?.();
    setIsDialogOpen(true);
  };

  const closeDetail = () => setIsDialogOpen(false);

  const [selectedTimeInterval, setSelectedTimeInterval] = useState<TimeInterval>(
    TimeInterval.PastThirtyDays,
  );

  const selectedTimeIntervalItemsFetcher = useCallback(
    async (
      requestOptions: ListingRequestOptionsModel,
      abortSignal: AbortSignal,
    ): Promise<ListingServerResponseModel<PublishedItemsServerModel>> => {
      const filter: PublishedItemsFilterModel = {
        timeInterval: selectedTimeInterval,
        languageIds: null,
        userIds: null,
      };

      const options: PublishedItemsListingRequestOptionsModel = {
        ...requestOptions,
        filter,
      };

      return await itemsFetcher(options, abortSignal);
    },
    [selectedTimeInterval, itemsFetcher],
  );

  const {
    fetchInit,
    state: itemsState,
    data: publishedItems,
  } = useWidgetListingDataFetcher(
    selectedTimeIntervalItemsFetcher,
    createPublishedListingContentItemRecordFromServerModel,
  );

  const [publishedItemsCount, setPublishedItemsCount] = useState<number>(0);
  const publishedItemsCountCallback = useCallback(async (): Promise<number> => {
    const abortController = new AbortController();
    const filter: PublishedItemsFilterModel = {
      timeInterval: selectedTimeInterval,
      languageIds: null,
      userIds: null,
    };
    const query: PublishedItemsCountRequestModel = { filter };

    return await itemsCountFetcher(query, abortController.signal);
  }, [selectedTimeInterval, itemsCountFetcher]);

  useEffect(() => {
    const fetchPublishedItemsCount = async () => {
      const count = await publishedItemsCountCallback();
      setPublishedItemsCount(count);
    };

    fetchPublishedItemsCount();
  }, [publishedItemsCountCallback]);

  useEffect(() => fetchInit(entityListWidgetDisplayCount).cancel, [fetchInit]);

  return (
    <>
      <Widget>
        <Widget.Title
          id={widgetTitleId}
          text="Recent published items"
          explanatoryLabel="Some items may not be visible due to the permissions set for your role, but they are included in the total count."
        />
        <Widget.Subtitle>
          {publishedItems.length !== 0 ? (
            <>
              <Box minWidth={10 * gridUnit} typography={Typography.HeadlineLarge}>
                {publishedItemsCount}
              </Box>
            </>
          ) : (
            <Box typography={Typography.HeadlineLarge}>--</Box>
          )}
        </Widget.Subtitle>
        <Widget.AuxElement>
          <SingleSelect
            aria-label="Select timeframe"
            items={timeIntervals}
            onSelectionChange={(timeInterval: TimeInterval) => {
              setSelectedTimeInterval(timeInterval);
            }}
            selectedItemId={selectedTimeInterval}
          />
        </Widget.AuxElement>
        <WidgetListingStateContent state={itemsState} renderEmptyState={() => <WidgetEmptyState />}>
          <Widget.Body>
            <StyledDataTable
              ariaLabelledBy={widgetTitleId}
              dataUiCollectionName={DataUiCollection.PublishedItems}
              header={<DataTableHeadRow columns={tableHeadColumns} />}
            >
              {publishedItems.slice(0, entityListWidgetDisplayCount).map((item) => {
                const path = getContentItemPath(
                  pathname,
                  item.contentItemId.itemId,
                  isPreviewOpen,
                  item.contentItemId.variantId,
                );

                return (
                  <PublishedItemsWidgetRow
                    collectionName={getCollectionNameFromId(collectionIds, item.collectionId)}
                    contentTypeName={getContentTypeNameFromId(contentTypeByIds, item.contentTypeId)}
                    key={item.id}
                    onItemClick={onOverviewItemClick}
                    pathToItem={path}
                    publishedItem={item}
                    rowFocusLinkAriaLabel={`visit content item — ${item.name}`}
                  />
                );
              })}
            </StyledDataTable>
          </Widget.Body>
          <Widget.Footer>
            <Widget.CenterContent>
              <WidgetListingContentShowMoreButton
                ariaDescribedBy={widgetTitleId}
                onClick={openDetail}
              />
            </Widget.CenterContent>
          </Widget.Footer>
        </WidgetListingStateContent>
      </Widget>
      <FullScreenModalDialog
        headline="Recent published items"
        isDismissable
        isOpen={isDialogOpen}
        onClose={closeDetail}
      >
        <PublishedItemsWidgetDetailContainer
          ariaLabelledBy={widgetTitleId}
          collectionIds={collectionIds}
          contentTypeByIds={contentTypeByIds}
          isPreviewOpen={isPreviewOpen}
          onItemClick={onDetailItemClick}
          selectedTimeInterval={selectedTimeInterval}
        />
      </FullScreenModalDialog>
    </>
  );
};

const tableHeadColumns: ReadonlyArray<Column> = [
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Name),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--3',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.ContentType),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--2',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Collection),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--2',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.LastPublishedAt),
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--2',
  },
];
