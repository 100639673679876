import { forwardRef } from 'react';
import { useHistory } from 'react-router';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getEditedContentItem } from '../../../../../_shared/selectors/getEditedContentItem.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { useAvailableCollectionsForSelectedLanguage } from '../../../../contentInventory/content/features/ContentItemInventory/selectors/useAvailableCollections.ts';
import { getCollection } from '../../../../contentInventory/content/selectors/getCollection.ts';
import { itemEditingModalDismissed } from '../../../actions/contentActions.ts';
import { ItemEditingModalDialogType } from '../../../constants/itemEditingModalDialogType.ts';
import { duplicateEditedContentItem } from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { useIsJustCreatedItemClone } from '../../ContentItemEditing/context/IsJustCreatedItemCloneContext.tsx';
import { redirectToItem } from '../../ContentItemEditing/utils/redirectToItem.ts';
import { duplicateItemWithoutContent } from '../actions/thunkDuplicateItemToCollectionActions.ts';
import { DuplicateToCollectionDialog as DuplicateToCollectionDialogComponent } from '../components/DuplicateToCollectionDialog.tsx';

const isDuplicateDialogType = (
  dialogType: ItemEditingModalDialogType,
): dialogType is
  | ItemEditingModalDialogType.DuplicateItemWithoutContent
  | ItemEditingModalDialogType.DuplicateItemWithContent => {
  return [
    ItemEditingModalDialogType.DuplicateItemWithoutContent,
    ItemEditingModalDialogType.DuplicateItemWithContent,
  ].includes(dialogType);
};

const useCreateDuplicateAction = (
  isPreviewOpen: boolean,
): ((destinationCollectionId?: Uuid) => Promise<void>) | null => {
  const dialogType = useSelector((state) => state.contentApp.editorUi.itemEditingModalDialog.type);
  const dispatch = useDispatch();
  const history = useHistory();

  const { setIsJustCreatedItemClone } = useIsJustCreatedItemClone();

  if (dialogType === ItemEditingModalDialogType.DuplicateItemWithContent) {
    return async (destinationCollectionId) => {
      const duplicateItem = await dispatch(duplicateEditedContentItem(destinationCollectionId));
      setIsJustCreatedItemClone(true);
      redirectToItem(history, duplicateItem.item.id, isPreviewOpen);
      dispatch(itemEditingModalDismissed());
    };
  }

  if (dialogType === ItemEditingModalDialogType.DuplicateItemWithoutContent) {
    return async (destinationCollectionId) => {
      const duplicateItem = await dispatch(
        duplicateItemWithoutContent(
          ContentItemEditingEventOrigins.MoreActions,
          destinationCollectionId,
        ),
      );
      redirectToItem(history, duplicateItem.duplicateItemId, isPreviewOpen);
      dispatch(itemEditingModalDismissed());
    };
  }

  return null;
};

export const DuplicateToCollectionDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  (props, ref) => {
    const dispatch = useDispatch();
    const currentProjectId = useSelector(getCurrentProjectId);
    const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

    const isExpectedDialogSet = useSelector((state) => {
      const type = state.contentApp.editorUi.itemEditingModalDialog.type;
      return isDuplicateDialogType(type) ? type : null;
    });

    const editedItemName = useSelector((s) => getEditedContentItem(s).name);
    const collectionName = useSelector((s) => {
      const item = getEditedContentItem(s);
      return getCollection(s, item.collectionId)?.name || '';
    });
    const editedContentItemTypeId = useSelector(
      (s) => getEditedContentItem(s).editedContentItemTypeId,
    );
    const collectionOptions = useAvailableCollectionsForSelectedLanguage(
      Capability.CreateContent,
      editedContentItemTypeId ?? '',
    );

    const onDuplicate = useCreateDuplicateAction(isLivePreviewPreferred);
    const onClose = compose(dispatch, itemEditingModalDismissed);

    if (!editedContentItemTypeId || !isExpectedDialogSet || !onDuplicate) {
      return null;
    }

    return (
      <DuplicateToCollectionDialogComponent
        collectionName={collectionName}
        collectionOptions={collectionOptions}
        itemName={editedItemName}
        onClose={onClose}
        onDuplicate={onDuplicate}
        ref={ref}
        {...props}
      />
    );
  },
);

DuplicateToCollectionDialog.displayName = 'DuplicateToCollectionDialog';
