import React, { memo, useContext } from 'react';
import {
  isAiChangeToneEnabled,
  isAiCreateTitleEnabled,
  isAiImproveContentEnabled,
  isAiMakeShorterEnabled,
  isAiMatchWritingStyleEnabled,
  isAiReviewEnabled,
  isAiSummarizeEnabled,
  isAnyAskAiFeatureEnabled,
  isInlineAiEnabled,
} from '../../../../_shared/selectors/aiSelectors.ts';
import { DataUiElement } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ReviewByGuidelinesPlugin } from '../../../../paperModels/aiReview/richText/plugins/ReviewByGuidelines/ReviewByGuidelinesPlugin.tsx';
import {
  EditorProps,
  emptyPluginComposition,
  useEditor,
  useOptionalPlugin,
  usePluginComposition,
} from '../../editorCore/Editor.composition.tsx';
import { ModalsPlugin } from '../../plugins/ModalsPlugin.tsx';
import { AiMenuPlugin } from '../../plugins/ai/AiMenuPlugin.tsx';
import { AiStylesPlugin } from '../../plugins/ai/AiStylesPlugin.tsx';
import { ChangeTonePlugin } from '../../plugins/ai/actions/ChangeTone/ChangeTonePlugin.tsx';
import { MatchWritingStyleOfItemPlugin } from '../../plugins/ai/actions/ChangeTone/MatchWritingStyleOfItem/MatchWritingStyleOfItemPlugin.tsx';
import { CreateTitlePlugin } from '../../plugins/ai/actions/CreateTitle/CreateTitlePlugin.tsx';
import { ImproveContentPlugin } from '../../plugins/ai/actions/ImproveContent/ImproveContentPlugin.tsx';
import { MakeShorterPlugin } from '../../plugins/ai/actions/MakeShorter/MakeShorterPlugin.tsx';
import { SummarizePlugin } from '../../plugins/ai/actions/Summarize/SummarizePlugin.tsx';
import { TranslateContentPlugin } from '../../plugins/ai/actions/TranslateContent/TranslateContentPlugin.tsx';
import { AiTranslationsContext } from '../../plugins/ai/actions/TranslateContent/contexts/AiTranslationsContext.tsx';
import { ApiLimitationsPlugin } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { TopLevelBlockCategoryFeature } from '../../plugins/apiLimitations/api/editorLimitationUtils.ts';
import { ApiStatisticsPlugin } from '../../plugins/apiStatistics/ApiStatisticsPlugin.tsx';
import { EditAssetPlugin } from '../../plugins/assets/EditAssetPlugin.tsx';
import { CompositionModeAdjustmentsPlugin } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { CustomCaretHandlingPlugin } from '../../plugins/behavior/CustomCaretHandlingPlugin.tsx';
import { CustomSelectionHandlingPlugin } from '../../plugins/behavior/CustomSelectionHandlingPlugin.tsx';
import { CustomTableSelectionPlugin } from '../../plugins/behavior/CustomTableSelectionPlugin.tsx';
import { FocusPlugin } from '../../plugins/behavior/FocusPlugin.tsx';
import { LockEditorPlugin } from '../../plugins/behavior/LockEditorPlugin.tsx';
import { OnChangePlugin } from '../../plugins/behavior/OnChangePlugin.tsx';
import { SpellCheckPlugin } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { ClearFormattingPlugin } from '../../plugins/clearFormatting/ClearFormattingPlugin.tsx';
import { ClipboardPlugin } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { CommentsPlugin } from '../../plugins/comments/CommentsPlugin.tsx';
import { ContentComponentsPlugin } from '../../plugins/contentComponents/ContentComponentsPlugin.tsx';
import { CustomBlocksPlugin } from '../../plugins/customBlocks/CustomBlocksPlugin.tsx';
import {
  CustomInputHandlingPlugin,
  EnterKeyBehavior,
} from '../../plugins/customInputHandling/CustomInputHandlingPlugin.tsx';
import { DraftJsPlugin } from '../../plugins/draftJs/DraftJsPlugin.tsx';
import { DragDropPlugin } from '../../plugins/dragDrop/DragDropPlugin.tsx';
import { DropFilesPlugin } from '../../plugins/dropFiles/DropFilesPlugin.tsx';
import { EntityApiPlugin } from '../../plugins/entityApi/EntityApiPlugin.tsx';
import { ImagesPlugin } from '../../plugins/images/ImagesPlugin.tsx';
import { InlineAiPlugin } from '../../plugins/inlineAi/InlineAiPlugin.tsx';
import { InlineStylesPlugin } from '../../plugins/inlineStyles/InlineStylesPlugin.tsx';
import { KeyboardShortcutsPlugin } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { richTextKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { LinkedItemsPlugin } from '../../plugins/linkedItems/LinkedItemsPlugin.tsx';
import { LinksPlugin } from '../../plugins/links/LinksPlugin.tsx';
import { AssetLinksPlugin } from '../../plugins/links/asset/AssetLinksPlugin.tsx';
import { ContentLinksPlugin } from '../../plugins/links/content/ContentLinksPlugin.tsx';
import { EmailLinksPlugin } from '../../plugins/links/email/EmailLinksPlugin.tsx';
import { PhoneLinksPlugin } from '../../plugins/links/phone/PhoneLinksPlugin.tsx';
import { AutomaticWebLinkConversionPlugin } from '../../plugins/links/web/AutomaticWebLinkConversionPlugin.tsx';
import { WebLinksPlugin } from '../../plugins/links/web/WebLinksPlugin.tsx';
import { SmartLinkPlugin } from '../../plugins/smartLink/SmartLinkPlugin.tsx';
import { TablesPlugin } from '../../plugins/tables/TablesPlugin.tsx';
import { TextApiPlugin } from '../../plugins/textApi/TextApiPlugin.tsx';
import { TextBlockTypesPlugin } from '../../plugins/textBlockTypes/TextBlockTypesPlugin.tsx';
import { BlockToolbarPlugin } from '../../plugins/toolbars/BlockToolbarPlugin.tsx';
import { InlineToolbarPlugin } from '../../plugins/toolbars/InlineToolbarPlugin.tsx';
import { UndoRedoPlugin } from '../../plugins/undoRedo/UndoRedoPlugin.tsx';
import { UploadFilesPlugin } from '../../plugins/uploadFiles/UploadFilesPlugin.tsx';
import { LimitationStylesPlugin } from '../../plugins/visuals/LimitationStylesPlugin.tsx';
import { PlaceholderPlugin } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { StylesPlugin } from '../../plugins/visuals/StylesPlugin.tsx';
import { WrapperPlugin } from '../../plugins/visuals/WrapperPlugin.tsx';
import { RichTextInputStatusPlugin } from './plugins/RichTextInputStatusPlugin.tsx';

const useDefaultComposition = () =>
  usePluginComposition(
    emptyPluginComposition,
    DraftJsPlugin,
    PlaceholderPlugin,
    WrapperPlugin,
    CompositionModeAdjustmentsPlugin,
    FocusPlugin,
    ApiLimitationsPlugin,
    ApiStatisticsPlugin,
    KeyboardShortcutsPlugin,
    TextApiPlugin,
    ClipboardPlugin,
    UndoRedoPlugin,
    StylesPlugin,
    LimitationStylesPlugin,
    ModalsPlugin,
    InlineToolbarPlugin,
    BlockToolbarPlugin,
    OnChangePlugin,
    DragDropPlugin,
    CustomInputHandlingPlugin,
    TextBlockTypesPlugin,
    InlineStylesPlugin,
    EntityApiPlugin,
    LinksPlugin,
    ClearFormattingPlugin,
    CommentsPlugin,
    CustomBlocksPlugin,
    EditAssetPlugin,
    ImagesPlugin,
    AutomaticWebLinkConversionPlugin,
    WebLinksPlugin,
    ContentLinksPlugin,
    AssetLinksPlugin,
    EmailLinksPlugin,
    PhoneLinksPlugin,
    UploadFilesPlugin,
    DropFilesPlugin,
    TablesPlugin,
    LinkedItemsPlugin,
    ContentComponentsPlugin,
    SmartLinkPlugin,
    SpellCheckPlugin,
    CustomCaretHandlingPlugin,
    RichTextInputStatusPlugin,
    CustomSelectionHandlingPlugin,
    CustomTableSelectionPlugin,
  );

const useCompositionWithAi = () => {
  const { isTranslationsFeatureEnabled } = useContext(AiTranslationsContext);

  return usePluginComposition(
    useDefaultComposition(),
    LockEditorPlugin,
    useOptionalPlugin(isAnyAskAiFeatureEnabled, AiMenuPlugin),
    useOptionalPlugin(isAiMakeShorterEnabled, MakeShorterPlugin),
    useOptionalPlugin(isAiImproveContentEnabled, ImproveContentPlugin),
    useOptionalPlugin(isAiChangeToneEnabled, ChangeTonePlugin),
    useOptionalPlugin(isAiMatchWritingStyleEnabled, MatchWritingStyleOfItemPlugin),
    useOptionalPlugin(isAiCreateTitleEnabled, CreateTitlePlugin),
    useOptionalPlugin(isAiSummarizeEnabled, SummarizePlugin),
    useOptionalPlugin(isInlineAiEnabled, AiStylesPlugin),
    useOptionalPlugin(isInlineAiEnabled, InlineAiPlugin),
    useOptionalPlugin(() => isTranslationsFeatureEnabled, TranslateContentPlugin),
    useOptionalPlugin(isAiReviewEnabled, ReviewByGuidelinesPlugin),
  );
};

export type RichTextInputProps = EditorProps<typeof useCompositionWithAi>;

export const RichTextInput: React.FC<RichTextInputProps> = memo((props) => {
  const composition = useCompositionWithAi();

  const isTextAllowed = props.limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const defaultPlaceholder = isTextAllowed ? 'Type your text here…' : 'Add your content here…';

  return useEditor(composition, {
    ...props,
    dataUiElement: props.dataUiElement ?? DataUiElement.RichTextEditor,
    enterKeyBehavior: EnterKeyBehavior.SoftNewLineWithShift,
    keyCommandMap: richTextKeyCommandMap,
    placeholder: props.placeholder ?? defaultPlaceholder,
    tabIndex: props.tabIndex ?? -1,
  });
});

RichTextInput.displayName = 'RichTextInput';
