import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { markEditedItemAsTemporary } from '../../../applications/itemEditor/features/ContentItemEditing/actions/contentItemEditingActions.ts';
import { createNewContentItem } from '../../../applications/itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { onboardingNotificationClosed } from '../../actions/sharedActions.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import { OnboardingNotificationBar } from '../../components/OnboardingNotifications/OnboardingNotificationBar.tsx';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { getContentItemPath } from '../../utils/routing/routeTransitionUtils.ts';

const Message = () => (
  <>
    🎉 Nice job! Your content type is now ready. 🎉 Create content based on this type to see it in
    action!
  </>
);

type Props = {
  readonly editedContentTypeId: Uuid;
  readonly languageIdToCreateItem: Uuid;
  readonly collectionIdToCreateItem: Uuid;
};

export const ContentTypeEditingOnboardingNotification = ({
  collectionIdToCreateItem,
  editedContentTypeId,
  languageIdToCreateItem,
}: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      trackUserEvent(TrackedEvent.FeatureUsed, {
        feature: 'onboarding-notification--displayed',
        info: 'content-type-editing',
      }),
    );
  }, []);

  const close = () => dispatch(onboardingNotificationClosed());

  const buttonClicked = async (): Promise<void> => {
    dispatch(
      trackUserEvent(TrackedEvent.FeatureUsed, {
        feature: 'onboarding-notification--cta',
        info: 'content-type-editing',
      }),
    );
    dispatch(onboardingNotificationClosed());

    const createdItem = await dispatch(
      createNewContentItem({ typeId: editedContentTypeId, collectionId: collectionIdToCreateItem }),
    );
    dispatch(
      markEditedItemAsTemporary({
        itemId: createdItem.item.id,
        variantId: languageIdToCreateItem,
        parentItem: null,
      }),
    );
    const contentItemPath = getContentItemPath(
      location.pathname,
      createdItem.item.id,
      false,
      languageIdToCreateItem,
    );
    history.push(contentItemPath);
  };

  return (
    <OnboardingNotificationBar
      buttonMessage="Create content based on this type"
      message={<Message />}
      onButtonClick={buttonClicked}
      onClose={close}
    />
  );
};
