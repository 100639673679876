import { Box } from '@kontent-ai/component-library/Box';
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ContentItemUsages } from '../../../data/reducers/listingContentItems/IListingContentItems.type.ts';
import { ContentItemUsagesLinksContainerProps } from '../../containers/ContentItemUsagesLinks/ContentItemUsagesLinks.tsx';
import { getContentItemPath } from '../../utils/routing/routeTransitionUtils.ts';

type ContentItemUsagesLinksProps = ContentItemUsagesLinksContainerProps &
  Readonly<{
    contentItemUsages: ContentItemUsages;
    isPreviewOpen: boolean;
  }>;

const DisplayedContentItemLinksCount = 2;

export const ContentItemUsagesLinks: React.FC<ContentItemUsagesLinksProps> = ({
  contentItemUsages,
  contentItemId,
  isPreviewOpen,
}) => {
  const history = useHistory();
  const usages = contentItemUsages.get(contentItemId);
  const usagesSize = usages?.size;

  if (!usagesSize) {
    return null;
  }

  return (
    <>
      {usages
        .take(DisplayedContentItemLinksCount)
        .toArray()
        .map(
          ({
            primary: {
              id: { itemId },
              name,
            },
          }) => (
            <Box key={itemId}>
              <Link
                to={getContentItemPath(history.location.pathname, itemId, isPreviewOpen)}
                target="_blank"
              >
                {name}
              </Link>
            </Box>
          ),
        )}
      {usagesSize > DisplayedContentItemLinksCount && (
        <>
          … and {usagesSize - DisplayedContentItemLinksCount} more. See Content details to find the
          whole list.
        </>
      )}
    </>
  );
};

ContentItemUsagesLinks.displayName = 'ContentItemUsagesLinks';
