import React, { useEffect } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { createYourContentItem } from '../../../../../data/models/listingContentItems/YourContentItem.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { IListingContentItemWithMetadataServerModel } from '../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  ListingRequestOptionsModel,
  ListingServerResponseModel,
} from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { entityListWidgetDisplayCount } from '../../shared/constants/widgetConstants.ts';
import {
  useTrackWidgetDetailItemClick,
  useTrackWidgetOverviewItemClick,
  useTrackWidgetShowMoreButtonClick,
} from '../../shared/hooks/tracking.ts';
import { useWidgetListingDataFetcher } from '../../shared/hooks/useWidgetListingDataFetcher.tsx';
import { RecentlyEditedByYouWidget } from '../components/RecentlyEditedByYouWidget.tsx';

export const RecentlyEditedByYouWidgetContainer: React.FC = () => {
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const {
    fetchInit,
    fetchMore,
    state: itemsState,
    data: recentlyEditedItems,
  } = useWidgetListingDataFetcher(itemsFetcher, createYourContentItem);

  useEffect(() => fetchInit(entityListWidgetDisplayCount).cancel, [fetchInit]);

  const trackWidgetOverviewItemClick = useTrackWidgetOverviewItemClick(
    MissionControlTrackedWidgetName.RecentlyEditedByYou,
  );
  const trackWidgetShowMoreButtonClick = useTrackWidgetShowMoreButtonClick(
    MissionControlTrackedWidgetName.RecentlyEditedByYou,
  );
  const trackWidgetDetailItemClick = useTrackWidgetDetailItemClick(
    MissionControlTrackedWidgetName.RecentlyEditedByYou,
  );

  return (
    <RecentlyEditedByYouWidget
      fetchMore={fetchMore}
      isPreviewOpen={isLivePreviewPreferred}
      items={recentlyEditedItems}
      itemsState={itemsState}
      onDetailItemClick={trackWidgetDetailItemClick}
      onItemClick={trackWidgetOverviewItemClick}
      onShowMoreClick={trackWidgetShowMoreButtonClick}
    />
  );
};

const itemsFetcher = async (
  _: ListingRequestOptionsModel,
  abortSignal: AbortSignal,
): Promise<ListingServerResponseModel<IListingContentItemWithMetadataServerModel>> => {
  const result =
    await repositoryCollection.contentItemRepository.getItemsRecentlyEditedByCurrentUser(
      abortSignal,
    );

  return {
    data: result,
    continuationToken: null,
  };
};
