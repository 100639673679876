import { Box } from '@kontent-ai/component-library/Box';
import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  Spacing,
  borderWidthDefault,
  colorBorderLowEmphasis,
  px,
} from '@kontent-ai/component-library/tokens';
import { useContext } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import {
  ContentItemPreviewWithEditorRoute,
  ContentItemsAppRootRoute,
  ContentItemsAppRootRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { isWebSpotlightIntegrationEnabled as isWebSpotlightIntegrationEnabledSelector } from '../../../../../../_shared/selectors/isWebSpotlightIntegrationEnabled.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { matchPath } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { PreviewApiDataStatus } from '../../../../../webSpotlight/models/PreviewApiDataStatus.ts';
import { getPreviewApiDataStatus } from '../../../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { isUrlPatternSetForEditedContentItemSelector } from '../../../../selectors/isUrlPatternSetForEditedContentItem.ts';
import { ItemEditingNewDesignContext } from '../../context/ItemEditingNewDesignContext.tsx';
import { PreviewActionsMenu } from '../../features/contentItemPreview/components/PreviewActionsMenu.tsx';
import { PreviewRefreshStatus } from '../../features/contentItemPreview/components/PreviewRefreshStatus.tsx';
import { RefreshPreviewButton } from '../../features/contentItemPreview/components/RefreshPreviewButton.tsx';
import { OpenInNewTabQuinaryButton } from '../../features/contentItemPreview/containers/OpenInNewTabQuinaryButton.tsx';
import { SpaceSelector } from '../../features/contentItemPreview/containers/SpaceSelector.tsx';
import { useNavigationTree } from '../../features/contentItemPreview/context/NavigationTreeContext.tsx';
import { useWebSpotlightInItemEditing } from '../../features/contentItemPreview/context/WebSpotlightInItemEditingContext.tsx';
import { useIsLivePreviewFeatureAvailable } from '../../hooks/useIsLivePreviewFeatureAvailable.ts';
import { useIsLivePreviewOpen } from '../../hooks/useIsLivePreviewOpen.ts';
import { getIsEditedItemInitialized } from '../../selectors/isEditedItemInitialized.ts';
import { WaitForEditedItemInitialized } from '../WaitForEditedItemInitialized.tsx';
import { ContentDetailsButton } from '../statusBar/ContentDetailsButton.tsx';
import { EditingActions } from './EditingActions.tsx';
import { PreviewToggle } from './PreviewToggle.tsx';
import { TreeToggle } from './TreeToggle.tsx';

export const EditingActionsBar = () => {
  const location = useLocation();
  const isInContentItemEditingApp =
    matchPath<ContentItemsAppRootRouteParams>(location.pathname, ContentItemsAppRootRoute)?.app ===
    ContentItemsAppRouteSegment.Content;
  const isNotPreviewWithLinkedItemEditorView = !useRouteMatch(ContentItemPreviewWithEditorRoute);

  const { isTreeAvailable } = useNavigationTree();
  const isPreviewOpen = useIsLivePreviewOpen();
  const isUrlPatternSetForEditedContentItem = useSelector(
    isUrlPatternSetForEditedContentItemSelector,
  );
  const isLivePreviewAvailable = useIsLivePreviewFeatureAvailable(
    isUrlPatternSetForEditedContentItem,
  );
  const showPreviewToggle = isLivePreviewAvailable || isPreviewOpen;

  const isNewDesign = useContext(ItemEditingNewDesignContext);
  const isWebSpotlightIntegrationEnabled = useSelector(isWebSpotlightIntegrationEnabledSelector);
  const { isAutoRefreshAvailable, isAutoRefreshInProgress } = useAutoRefresh();

  const isEditedItemInitialized = useSelector((s) =>
    getIsEditedItemInitialized(s, location.pathname),
  );

  const isThereAnythingToDisplay = isTreeAvailable || showPreviewToggle || isEditedItemInitialized;
  if (!isThereAnythingToDisplay) {
    return null;
  }

  return isNewDesign ? (
    <Box
      css={`
        grid-area: editing-actions;
        border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis};
    `}
      padding={Spacing.L}
    >
      <Row
        alignY="center"
        noWrap
        {...getDataUiElementAttribute(DataUiElement.ContentItemActionBar)}
      >
        {(isTreeAvailable || showPreviewToggle) && (
          <Column width="content">
            <Inline spacingX={Spacing.M}>
              {isTreeAvailable && <TreeToggle />}
              {showPreviewToggle && <PreviewToggle />}
              {isLivePreviewAvailable && isPreviewOpen && (
                <>
                  <Divider
                    direction={DividerDirection.Vertical}
                    offsetBefore={Spacing.None}
                    offsetAfter={Spacing.None}
                  />
                  <SpaceSelector />
                  <Divider
                    direction={DividerDirection.Vertical}
                    offsetBefore={Spacing.None}
                    offsetAfter={Spacing.None}
                  />
                  {isAutoRefreshAvailable ? (
                    <PreviewActionsMenu
                      isWebSpotlightIntegrationEnabled={isWebSpotlightIntegrationEnabled}
                    />
                  ) : (
                    <RefreshPreviewButton isCompact />
                  )}
                  <OpenInNewTabQuinaryButton isCompact />
                  {isAutoRefreshInProgress && <PreviewRefreshStatus />}
                </>
              )}
            </Inline>
          </Column>
        )}
        {isNotPreviewWithLinkedItemEditorView && (
          <WaitForEditedItemInitialized>
            <Column width="content">
              {/* reserve some space so that editing actions and buttons on the left don’t touch */}
              <Box width={Spacing.XXL} />
            </Column>
            <Column>
              <EditingActions />
            </Column>
            {isInContentItemEditingApp && (
              <Column width="content">
                <Inline>
                  <Divider
                    direction={DividerDirection.Vertical}
                    offsetBefore={Spacing.S}
                    offsetAfter={Spacing.S}
                  />
                  <ContentDetailsButton isQuinary />
                </Inline>
              </Column>
            )}
          </WaitForEditedItemInitialized>
        )}
      </Row>
    </Box>
  ) : (
    <Box
      css={`
        grid-area: editing-actions;
        border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis};
    `}
      paddingX={Spacing.L}
      paddingY={Spacing.S}
    >
      <Row
        alignY="center"
        noWrap
        spacingX={Spacing.M}
        {...getDataUiElementAttribute(DataUiElement.ContentItemActionBar)}
      >
        {(isTreeAvailable || showPreviewToggle) && (
          <Column width="content">
            <Inline spacingX={Spacing.S}>
              {isTreeAvailable && <TreeToggle />}
              {showPreviewToggle && <PreviewToggle />}
            </Inline>
          </Column>
        )}
        {isNotPreviewWithLinkedItemEditorView && (
          <WaitForEditedItemInitialized>
            <Column>
              <EditingActions />
            </Column>
            {isInContentItemEditingApp && (
              <Column width="content">
                <ContentDetailsButton />
              </Column>
            )}
          </WaitForEditedItemInitialized>
        )}
      </Row>
    </Box>
  );
};

const useAutoRefresh = () => {
  const { isAutoRefreshAvailable, isAutoRefreshEnabledByUser, supportedSmartLinkFeatures } =
    useWebSpotlightInItemEditing();

  const isAutoRefreshEnabled =
    isAutoRefreshEnabledByUser && !!supportedSmartLinkFeatures?.refreshHandler;
  const isRefreshing = useSelector(
    (s) => getPreviewApiDataStatus(s) === PreviewApiDataStatus.Outdated,
  );

  return { isAutoRefreshAvailable, isAutoRefreshInProgress: isAutoRefreshEnabled && isRefreshing };
};
