import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Spacing,
  spacingElementEdgeHorizontal,
  spacingElementEdgeVertical,
} from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import React, { useEffect } from 'react';
import { isUuid } from '../../../../../../../_shared/utils/validation/typeValidators.ts';
import { IListingContentItem } from '../../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { WebSpotlightTreeNode } from '../../../../../../webSpotlight/components/tree/WebSpotlightTreeNode.tsx';
import { IExpandedNodesData } from '../../../../../../webSpotlight/reducers/expandedNodesData.ts';
import { NodeId } from '../../../../../../webSpotlight/types/nodeId.type.ts';
import { WebSpotlightTabName } from '../../../../../../webSpotlight/types/webSpotlightTabName.ts';
import {
  checkIfItemHasSubpages,
  getNodeIdFromPath,
  getSelectedItemIdFromPath,
} from '../../../../../../webSpotlight/utils/webSpotlightUtils.ts';
import { IEditedContentItem } from '../../../../../models/contentItem/edited/EditedContentItem.ts';

const WebSpotlightRootItemDepth = 0;

type Props = Readonly<{
  readonly contentItems: Immutable.Map<Uuid, IListingContentItem>;
  readonly currentPath: string;
  readonly editedContentItem: IEditedContentItem | null;
  readonly expandedNodesData: Immutable.Map<NodeId, IExpandedNodesData>;
  readonly focusedNodeId: NodeId;
  readonly isPreviewOpen: boolean;
  readonly nodesBeingLoaded: Immutable.Set<NodeId>;
  readonly onNodeCollapsed: (nodeId: NodeId) => void;
  readonly onNodeExpanded: (nodeId: NodeId, itemId: Uuid) => void;
  readonly onNodeSelected: (nodeId: NodeId, itemId: Uuid, nodePath: string) => void;
  readonly rootItemId: Uuid;
  readonly rootPath: string;
  readonly selectedLanguageId: Uuid;
  readonly subpagesById: Immutable.Map<Uuid, UuidArray>;
}>;

export const NavigationTree: React.FC<Props> = ({
  contentItems,
  currentPath,
  editedContentItem,
  expandedNodesData,
  focusedNodeId,
  isPreviewOpen,
  nodesBeingLoaded,
  onNodeCollapsed,
  onNodeExpanded,
  onNodeSelected,
  rootItemId,
  rootPath,
  selectedLanguageId,
  subpagesById,
}) => {
  const selectedNodeId = getNodeIdFromPath(currentPath);
  const selectedItemId = getSelectedItemIdFromPath(currentPath);
  const canSelectedNodeBeExpanded =
    isUuid(selectedItemId) && checkIfItemHasSubpages(subpagesById, contentItems, selectedItemId);

  useEffect(() => {
    if (canSelectedNodeBeExpanded) {
      onNodeExpanded(selectedNodeId, selectedItemId);
    }
  }, [canSelectedNodeBeExpanded, onNodeExpanded, selectedItemId, selectedNodeId]);

  return (
    <div className="web-spotlight-tree">
      <div className="web-spotlight-tree__nodes-wrapper">
        <Box
          overflowX="hidden"
          overflowY="auto"
          paddingTop={Spacing.S}
          paddingX={spacingElementEdgeHorizontal}
          paddingBottom={spacingElementEdgeVertical}
        >
          <Stack spacing={Spacing.L}>
            <ul className="web-spotlight-tree__node web-spotlight-tree__node--is-root">
              <WebSpotlightTreeNode
                contentItems={contentItems}
                currentPath={currentPath}
                depth={WebSpotlightRootItemDepth}
                editedContentItem={editedContentItem}
                expandedNodesData={expandedNodesData}
                focusedNodeId={focusedNodeId}
                itemId={rootItemId}
                nodesBeingLoaded={nodesBeingLoaded}
                onNodeCollapsed={onNodeCollapsed}
                onNodeExpanded={onNodeExpanded}
                onNodeSelected={onNodeSelected}
                parentPath={rootPath}
                selectedNodeId={selectedNodeId}
                selectedLanguageId={selectedLanguageId}
                selectedTab={
                  isPreviewOpen ? WebSpotlightTabName.Preview : WebSpotlightTabName.Editor
                }
                subpagesById={subpagesById}
              />
            </ul>
          </Stack>
        </Box>
      </div>
    </div>
  );
};
