import Immutable from 'immutable';
import { IContentItemVariantReference } from '../../applications/itemEditor/models/contentItem/ContentItemVariantReference.ts';
import { Languages } from '../../data/models/languages/Language.ts';
import { ContentItemRouteParams, ContentItemsAppRouteSegment } from '../constants/routePaths.ts';
import { buildPath, pickContentItemRoute } from './routing/routeTransitionUtils.ts';

export type ContentItemVariantWithLanguageName = Readonly<
  IContentItemVariantReference & {
    languageName: string;
    linkPath: string;
  }
>;

export const getContentItemVariantsWithLanguageNames = (
  contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>,
  languages: Languages,
  currentProjectId: Uuid,
  isPreviewOpen: boolean,
): ReadonlyArray<ContentItemVariantWithLanguageName> => {
  if (!currentProjectId) {
    return [];
  }

  return contentItemVariants
    .filter((variant) => !variant.isArchived)
    .map((variant) => {
      const linkPath = buildPath<ContentItemRouteParams<UuidArray>>(
        pickContentItemRoute(isPreviewOpen),
        {
          projectId: currentProjectId,
          app: ContentItemsAppRouteSegment.Content,
          variantId: variant.id.variantId,
          spaceId: undefined,
          contentItemIds: [variant.id.itemId],
        },
      );

      const language = languages.get(variant.id.variantId);
      return {
        ...variant,
        languageName: language?.name || '',
        linkPath,
      };
    })
    .toArray();
};
