import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { useLivePreviewPreferenceStorage } from '../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { initEditItemVariantSmartLink } from '../actions/thunks/initEditItemSmartLink.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { EditItemRouteParams } from '../constants/routePaths.ts';

export const EditItemSmartLink = () => {
  const location = useLocation();
  const { itemGuid: itemId, variantGuid: variantId, projectId } = useParams<EditItemRouteParams>();
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(projectId);

  const params = useMemo(
    () => ({
      projectId,
      itemId,
      variantId,
      location,
      isPreviewOpen: isLivePreviewPreferred,
    }),
    [projectId, itemId, variantId, location, isLivePreviewPreferred],
  );

  useThunkPromise(initEditItemVariantSmartLink, params);

  return <SmartLink />;
};
