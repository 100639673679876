import { Collection, alphabetically, createCompare, noOperation } from '@kontent-ai/utils';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { VariantRouteParams } from '../../../../../../_shared/constants/routePaths.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { useDataSelector } from '../../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentItemFilterOrigin } from '../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { areCollectionsVisibleForSpaces } from '../../../../../../_shared/selectors/contentCollections.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getIsAiSearchUsed } from '../../../../../../paperModels/aiSearch/utils/getIsAiSearchUsed.ts';
import { ContentTypeAsComponentUsageDialogContext } from '../../../../../contentModels/contentTypes/context/ContentTypeAsComponentUsageDialogContext.tsx';
import { areSpacesInCollectionsEnabled as areSpacesInCollectionsEnabledSelector } from '../../../../../environmentSettings/selectors/allowedFeaturesSelectors.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { isFullTextSearchUnavailableOrDisabled } from '../../../../shared/selectors/fullTextSearchStatus.ts';
import {
  onCollectionFilterChanged,
  onContentItemStatusFilterChanged,
  onContentTypeFilterChanged,
  onContributorsFilterChanged,
  onPublishingStatusFilterChanged,
  onSitemapFilterChanged,
  onSpaceFilterChanged,
  onTaxonomyGroupsFilterChanged,
  onWorkflowStatusFilterChanged,
} from '../actions/listingFilterActions.ts';
import { createLinkedFilter, saveFilter } from '../actions/thunkListingFilterActions.ts';
import { ContentItemFilter as ContentItemFilterComponent } from '../components/ContentItemFilter.tsx';
import {
  ContentItemFilterSelectors,
  ContentItemFilterSelectorsOld,
} from '../components/ContentItemFilterSelectors.tsx';

type Props = {
  readonly clearFilter: () => void;
  readonly forceContentTypesTooltipText?: string | undefined;
  readonly forcedContentTypeIds?: ReadonlySet<Uuid>;
  readonly hideVariantSpecificInfo?: boolean;
  readonly origin: ContentItemFilterOrigin;
};

export const ContentItemFilter = (props: Props) => {
  const { variantId } = useParams<VariantRouteParams>();

  const savedFiltersUi = useSelector((s) => s.contentApp.listingUi.savedFiltersUi);
  const filtersById = useDataSelector((data) => data.filters.byId);
  const storedFilters = Collection.getValues(filtersById).toSorted(
    createCompare({
      compare: alphabetically,
      select: (t) => t.name,
    }),
  );
  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  const isFilterBeingCreated = useSelector(
    (s) => s.contentApp.listingUi.savedFiltersUi.isFilterBeingCreated,
  );
  const user = useDataSelector((data) => data.user);

  const isInContentTypeAsComponentUsageDialog = useContext(
    ContentTypeAsComponentUsageDialogContext,
  );
  const displayTaxonomyWarningStatus = useSelector((s) => {
    const fullTextSearchUnavailableOrDisabled = isFullTextSearchUnavailableOrDisabled(s);
    const isSelectedLanguageDefault = getSelectedLanguageId(s) === DefaultVariantId;
    return (
      fullTextSearchUnavailableOrDisabled &&
      (!isSelectedLanguageDefault || isInContentTypeAsComponentUsageDialog)
    );
  });
  const areSpacesInCollectionsEnabled = useSelector(areSpacesInCollectionsEnabledSelector);

  const dispatch = useDispatch();

  const displaySpacesFilter = useSelector((state) =>
    areCollectionsVisibleForSpaces(state, Collection.getValues(state.data.collections.byId)),
  );

  const isAiSearchUsed = useSelector((s) => getIsAiSearchUsed(s.contentApp.listingUi.filter));

  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  return (
    <ContentItemFilterComponent
      filter={filter}
      filters={storedFilters}
      forcedContentTypeIds={props.forcedContentTypeIds}
      hideVariantSpecificInfo={props.hideVariantSpecificInfo}
      isFilterBeingCreated={isFilterBeingCreated}
      onClearFilter={props.clearFilter}
      onCreateLinkedFilter={() => dispatch(createLinkedFilter())}
      onSaveFilter={(name) => dispatch(saveFilter({ name }))}
      origin={props.origin}
      renderSelectors={() =>
        isRefinedNavigationFeatureEnabled ? (
          <ContentItemFilterSelectors
            areSpacesInCollectionsEnabled={areSpacesInCollectionsEnabled}
            displaySpacesFilter={displaySpacesFilter}
            displayTaxonomyWarningStatus={displayTaxonomyWarningStatus}
            filter={filter}
            forceContentTypesTooltipText={props.forceContentTypesTooltipText}
            forcedContentTypeIds={props.forcedContentTypeIds}
            isAiSearchUsed={isAiSearchUsed}
            languageId={variantId}
            onCollectionSelectionChanged={(ids) => dispatch(onCollectionFilterChanged(ids))}
            onContentItemStatusSelectionChanged={(status) =>
              dispatch(onContentItemStatusFilterChanged(status))
            }
            onContentTypesSelectionChanged={
              props.forcedContentTypeIds
                ? noOperation
                : (ids) => dispatch(onContentTypeFilterChanged(ids))
            }
            onContributorsSelectionChanged={(ids) => dispatch(onContributorsFilterChanged(ids))}
            onPublishingStatusSelectionChanged={(publishingStatus) =>
              dispatch(onPublishingStatusFilterChanged(publishingStatus))
            }
            onSitemapSelectionChanged={(selectedSitemapNodes) =>
              dispatch(onSitemapFilterChanged(selectedSitemapNodes))
            }
            onSpaceSelectionChanged={(ids) => dispatch(onSpaceFilterChanged(ids))}
            onTaxonomySelectionChanged={(groupToTerms) =>
              dispatch(onTaxonomyGroupsFilterChanged(groupToTerms))
            }
            onWorkflowsSelectionChanged={(...args) =>
              dispatch(onWorkflowStatusFilterChanged(...args))
            }
            user={user}
          />
        ) : (
          <ContentItemFilterSelectorsOld
            areSpacesInCollectionsEnabled={areSpacesInCollectionsEnabled}
            displaySpacesFilter={displaySpacesFilter}
            displayTaxonomyWarningStatus={displayTaxonomyWarningStatus}
            filter={filter}
            forceContentTypesTooltipText={props.forceContentTypesTooltipText}
            forcedContentTypeIds={props.forcedContentTypeIds}
            isAiSearchUsed={isAiSearchUsed}
            languageId={variantId}
            onCollectionSelectionChanged={(ids) => dispatch(onCollectionFilterChanged(ids))}
            onContentItemStatusSelectionChanged={(status) =>
              dispatch(onContentItemStatusFilterChanged(status))
            }
            onContentTypesSelectionChanged={
              props.forcedContentTypeIds
                ? noOperation
                : (ids) => dispatch(onContentTypeFilterChanged(ids))
            }
            onContributorsSelectionChanged={(ids) => dispatch(onContributorsFilterChanged(ids))}
            onPublishingStatusSelectionChanged={(publishingStatus) =>
              dispatch(onPublishingStatusFilterChanged(publishingStatus))
            }
            onSitemapSelectionChanged={(selectedSitemapNodes) =>
              dispatch(onSitemapFilterChanged(selectedSitemapNodes))
            }
            onSpaceSelectionChanged={(ids) => dispatch(onSpaceFilterChanged(ids))}
            onTaxonomySelectionChanged={(groupToTerms) =>
              dispatch(onTaxonomyGroupsFilterChanged(groupToTerms))
            }
            onWorkflowsSelectionChanged={(...args) =>
              dispatch(onWorkflowStatusFilterChanged(...args))
            }
            user={user}
          />
        )
      }
      savedFilters={filtersById}
      savedFiltersUi={savedFiltersUi}
    />
  );
};

export const isContentItemFilterInitialized = (
  origin: ContentItemFilterOrigin,
  filterStatus: ReadonlyRecord<ContentItemFilterOrigin, LoadingStatus>,
): boolean => filterStatus[origin] === LoadingStatus.Loaded;
