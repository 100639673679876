import { Collection, noOperation } from '@kontent-ai/utils';
import { useFocusWithin, useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import {
  FocusEventHandler,
  type HTMLAttributes,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RefinedNavigationToggleId } from '../constants/RefinedNavigationToggleId.ts';
import { useNavigationState } from '../hooks/useNavigationState.ts';

export enum NavigationBarExpandedState {
  Minimized = 'minimized',
  MinimizedExpanded = 'minimized-expanded',
  Expanded = 'expanded',
}

type MainNavigationContextState = {
  readonly navigationBarProps: Omit<HTMLAttributes<HTMLElement>, 'color'>;
  readonly navigationBarExpandedState: NavigationBarExpandedState;
  readonly expandedNavigationToggleIds: ReadonlySet<RefinedNavigationToggleId>;
  readonly toggleNavigationToggle: (toggleId: RefinedNavigationToggleId) => void;
  readonly toggleIsNavigationBarExpanded: () => void;
};

type Props = {
  readonly children: ReactNode;
};

export const MainNavigationContext = createContext<MainNavigationContextState>({
  navigationBarProps: {},
  expandedNavigationToggleIds: new Set<RefinedNavigationToggleId>(),
  navigationBarExpandedState: NavigationBarExpandedState.Minimized,
  toggleIsNavigationBarExpanded: noOperation,
  toggleNavigationToggle: noOperation,
});
MainNavigationContext.displayName = 'MainNavigationContext';

export const MainNavigationContextProvider = (props: Props) => {
  const { hoverProps, isHovered } = useHover({});
  const [navigationState, setNavigationState] = useNavigationState();

  const { expandedNavigationToggleIds, isNavigationBarExpanded } = navigationState;

  const toggleNavigationToggle = useCallback(
    (toggleId: RefinedNavigationToggleId) => {
      setNavigationState((prev) => ({
        ...prev,
        expandedNavigationToggleIds: Collection.togglePresence(
          prev.expandedNavigationToggleIds,
          toggleId,
        ),
      }));
    },
    [setNavigationState],
  );

  const toggleIsNavigationBarExpanded = useCallback(() => {
    setNavigationState((prev) => ({
      ...prev,
      isNavigationBarExpanded: !prev.isNavigationBarExpanded,
    }));
  }, [setNavigationState]);

  const [navigationBarExpandedState, setNavigationBarExpandedState] =
    useState<NavigationBarExpandedState>(NavigationBarExpandedState.Minimized);

  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocusWithin) => {
      setNavigationBarExpandedState(
        isFocusWithin
          ? NavigationBarExpandedState.MinimizedExpanded
          : NavigationBarExpandedState.Minimized,
      );
    },
    isDisabled: isNavigationBarExpanded,
  });

  const onFocus: FocusEventHandler = useCallback(() => {
    if (isNavigationBarExpanded) {
      return;
    }
    setNavigationBarExpandedState(NavigationBarExpandedState.MinimizedExpanded);
  }, [isNavigationBarExpanded]);

  useEffect(() => {
    if (isNavigationBarExpanded) {
      setNavigationBarExpandedState(NavigationBarExpandedState.Expanded);
      return;
    }

    if (isHovered) {
      setNavigationBarExpandedState(NavigationBarExpandedState.MinimizedExpanded);
      return;
    }

    setNavigationBarExpandedState(NavigationBarExpandedState.Minimized);
  }, [isNavigationBarExpanded, isHovered]);

  const contextValue = useMemo<MainNavigationContextState>(
    () => ({
      navigationBarProps: { ...mergeProps(hoverProps, focusWithinProps, { onFocus }) },
      navigationBarExpandedState,
      expandedNavigationToggleIds,
      toggleNavigationToggle,
      toggleIsNavigationBarExpanded,
    }),
    [
      hoverProps,
      focusWithinProps,
      onFocus,
      navigationBarExpandedState,
      expandedNavigationToggleIds,
      toggleNavigationToggle,
      toggleIsNavigationBarExpanded,
    ],
  );

  return (
    <MainNavigationContext.Provider value={contextValue}>
      {props.children}
    </MainNavigationContext.Provider>
  );
};
