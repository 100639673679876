import { ReactNode, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  ContentItemPreviewRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../_shared/constants/routePaths.ts';
import {
  buildPath,
  parseContentItemIds,
} from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { LivePreviewConfigurationContext } from '../features/ContentItemEditing/features/contentItemPreview/context/LivePreviewConfigurationContext.tsx';

type Props = Readonly<{
  children: ReactNode;
}>;

export const RedirectIfLivePreviewNotEnabled = ({ children }: Props) => {
  const history = useHistory();
  const { isLivePreviewEnabled } = useContext(LivePreviewConfigurationContext);

  const { projectId, spaceId, variantId, contentItemIds } =
    useParams<ContentItemPreviewRouteParams<string>>();

  useEffect(() => {
    if (!isLivePreviewEnabled) {
      const path = buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
        projectId,
        app: ContentItemsAppRouteSegment.Content,
        variantId,
        spaceId,
        contentItemIds: parseContentItemIds(contentItemIds),
      });

      history.replace(path);
    }
  }, [history, projectId, variantId, spaceId, contentItemIds, isLivePreviewEnabled]);

  return children;
};
